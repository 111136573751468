import useSWR from 'swr'

import { fetchUsers } from 'services/Users'

export const useForecasters = () => {
    const key = ['products/forecasters']
    const { data, isLoading, mutate } = useSWR(key, () => fetchUsers())

    return {
        forecasters: data?.data,
        isLoading,
        mutate,
    }
}
