import { useState } from 'react'

import { Button, Col, Collapse, Modal, Row, Tag } from 'antd'

import { EditScreenLayout } from 'components/EditScreenLayout/EditScreenLayout'
import { FormattedMessage, useIntl } from 'react-intl'
import messages from 'services/intl/messageDefinitions'
import { useContent } from 'stores/ForecastStore'
import { useLocale } from 'stores/UserStore'
import { areAllFieldsComplete } from './ImageCard'
import { ImageForm } from './ImageForm'
import { PanelContents } from './PanelContents'
import { addImage, removeImage, switchTwoImages } from './helpers'

import { ModalFormFooter } from './ModalFormFooter'
import './NewMedia.css'

const { Panel } = Collapse

export const SECTIONS = [
    { title: 'avalanche summary', translationKey: 'avalancheSummary', tag: 'avalanche' },
    { title: 'snowpack summary', translationKey: 'snowpackSummary', tag: 'snowpack' },
    { title: 'weather summary', translationKey: 'weatherSummary', tag: 'weather' },
    { title: 'headline', translationKey: 'headline', tag: 'headline', disabled: true },
    {
        title: 'terrain and travel advice',
        translationKey: 'terrainAndTravelAdvice',
        tag: 'terrainAndTravelAdvice',
        disabled: true,
    },
    { title: 'avalanche problems', translationKey: 'avalancheProblems', tag: 'avalancheProblems', disabled: true },
]
const FIRST_COLUMN = [SECTIONS[0], SECTIONS[1], SECTIONS[2]]
const SECOND_COLUMN = [SECTIONS[3], SECTIONS[4], SECTIONS[5]]

export const NewMedia = ({ update, compressed }) => {
    const content = useContent()
    const { media } = content
    const locale = useLocale()
    const intl = useIntl()

    const defaultActiveKeys = SECTIONS.filter(section => !section.disabled).map(section => section.tag)

    const [editImageModalVisible, setEditImageModalVisible] = useState(false)
    const [currentSection, setCurrentSection] = useState(null)
    const [currentImage, setCurrentImage] = useState(null)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)

    const handleImageClick = (image, section) => {
        setCurrentSection(section)
        setCurrentImage(image)
        setEditImageModalVisible(true)
    }

    const handleCancelClick = image => {
        // Removes the image if it was added but no image was uploaded
        if (image && !image.url) {
            const updatedProduct = removeImage(content, image.id)
            update(updatedProduct)
        }

        setEditImageModalVisible(false)
        setCurrentSection(null)
        setCurrentImage(null)
    }

    const handleCancelDeleteModal = () => {
        setDeleteModalVisible(false)
        setCurrentSection(null)
        setCurrentImage(null)
    }

    const handleAddImageClick = section => {
        const updatedProduct = addImage(content, locale, section.tag)
        update(updatedProduct)

        const newImage = updatedProduct.media.images[updatedProduct.media.images.length - 1]
        handleImageClick(newImage, section)
    }

    const handleLeftClick = (event, image, sectionImages) => {
        event.stopPropagation()

        const currentIndex = sectionImages.findIndex(i => i.id === image.id)
        const previousImage = sectionImages[currentIndex - 1]

        const updatedProduct = switchTwoImages(content, image, previousImage)
        update(updatedProduct)
    }

    const handleRightClick = (event, image, sectionImages) => {
        event.stopPropagation()

        const currentIndex = sectionImages.findIndex(i => i.id === image.id)
        const nextImage = sectionImages[currentIndex + 1]

        const updatedProduct = switchTwoImages(content, image, nextImage)
        update(updatedProduct)
    }

    const handleDeleteClick = (event, image) => {
        event.stopPropagation()

        setCurrentImage(image)
        setDeleteModalVisible(true)
    }

    const deleteImage = image => {
        const updatedProduct = removeImage(content, image.id)
        update(updatedProduct)

        setDeleteModalVisible(false)
    }

    const handleUpdate = async updatedProduct => {
        await update(updatedProduct)

        const updatedImage = updatedProduct.media.images.find(i => i.id === currentImage.id)
        setCurrentImage(updatedImage)
    }

    const currentSectionImages = currentSection ? media.images.filter(i => i.tag === currentSection.tag) : []
    const currentImageIndex = currentSectionImages.findIndex(i => i.id === currentImage?.id)

    const titleImageCount = currentImage
        ? (currentImageIndex + 1).toString() + '/' + currentSectionImages.length
        : `${currentSectionImages.length + 1}/${currentSectionImages.length + 1}`
    const titleSection = currentSection ? intl.formatMessage({ ...messages[currentSection.translationKey] }) : ''
    const title = `${titleImageCount} ${titleSection}`

    return (
        <>
            <EditScreenLayout showDaySelector={false} compressed={compressed}>
                <Row>
                    {[FIRST_COLUMN, SECOND_COLUMN].map((column, i) => {
                        return (
                            <Col span={12} key={i}>
                                <Collapse defaultActiveKey={defaultActiveKeys} style={styles.mainContainer}>
                                    {column.map(section => {
                                        const collapsible = section.disabled ? 'disabled' : 'header'
                                        const sectionImages = media && media.images.filter(i => i.tag === section.tag)
                                        const isAnImageIncomplete =
                                            sectionImages && sectionImages.some(i => !areAllFieldsComplete(i, locale))
                                        const sectionTitle = (
                                            <>
                                                <FormattedMessage {...messages[section.translationKey]} />
                                                {isAnImageIncomplete && (
                                                    <Tag color="red" style={{ marginLeft: 'var(--s-1)' }}>
                                                        <FormattedMessage {...messages.incomplete} />
                                                    </Tag>
                                                )}
                                            </>
                                        )

                                        return (
                                            <Panel header={sectionTitle} key={section.tag} collapsible={collapsible}>
                                                <PanelContents
                                                    section={section}
                                                    sectionImages={sectionImages}
                                                    handlers={{
                                                        handleLeftClick,
                                                        handleDeleteClick,
                                                        handleRightClick,
                                                        handleImageClick,
                                                        handleAddImageClick,
                                                    }}
                                                />
                                            </Panel>
                                        )
                                    })}
                                </Collapse>
                            </Col>
                        )
                    })}
                </Row>
            </EditScreenLayout>
            <Modal
                title={title}
                centered
                destroyOnClose
                maskClosable={false}
                visible={editImageModalVisible}
                onCancel={() => handleCancelClick(currentImage)}
                width={'95%'}
                footer={<ModalFormFooter handleCancelClick={handleCancelClick} image={currentImage} />}
            >
                <ImageForm image={currentImage} apiPut={handleUpdate} />
            </Modal>
            <Modal
                title={<FormattedMessage {...messages.warning} />}
                visible={deleteModalVisible}
                onOk={() => deleteImage(currentImage)}
                onCancel={() => handleCancelDeleteModal()}
                okText={<FormattedMessage {...messages.deleteImageConfirmationButton} />}
                cancelText={<FormattedMessage {...messages.cancel} />}
            >
                <p>
                    <FormattedMessage {...messages.deleteImageConfirmation} />
                </p>
            </Modal>
        </>
    )
}

const styles = {
    mainContainer: {
        margin: 'var(--s-1)',
    },
}
