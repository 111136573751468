import React from 'react'

import { Row, Col, Tag, Divider } from 'antd'
import { ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined } from '@ant-design/icons'
import './Statement.css'

export const Statement = ({ statement, disabled, selected, handleClick, handleUp, handleDown, handleDelete }) => {
    // ID is used to focus on the statement when it is selected in accessibility mode
    // If the statement is selected and not disabled then it is the statement shown above in the selected statements
    return (
        <div
            // We have made these elements interactive
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            id={selected ? (disabled ? null : `selected-statement-${statement.key}`) : `statement-${statement.key}`}
        >
            <Divider style={styles.divider} />
            <div
                onClick={() => handleClick(statement)}
                className={'tagStatement'}
                style={{ cursor: selected ? null : 'pointer' }}
            >
                <Row gutter={2} data-test={`statement${statement.key}`}>
                    <Col span={handleDelete ? 22 : 24}>
                        <Row>
                            {statement.tags.map(tag => {
                                return (
                                    <div style={{ ...styles.tag, opacity: disabled ? 0.5 : 1 }} key={tag}>
                                        <Tag color="orange">{tag}</Tag>
                                    </div>
                                )
                            })}
                        </Row>
                        <Row style={{ opacity: disabled ? 0.5 : 1 }}>
                            <p style={styles.statementText}>{statement.message}</p>
                        </Row>
                    </Col>
                    {handleDelete && (
                        <Col span={2} style={styles.iconRow}>
                            <Row>
                                <Col span={24}>
                                    {handleUp && (
                                        <ArrowUpOutlined
                                            style={{ ...styles.icons, marginTop: 0 }}
                                            onClick={() => handleUp(statement)}
                                            data-test={`statement${statement.key}Up`}
                                        />
                                    )}
                                </Col>
                                <Col span={24}>
                                    <DeleteOutlined
                                        style={styles.icons}
                                        onClick={() => handleDelete(statement)}
                                        data-test={`statement${statement.key}Delete`}
                                    />
                                </Col>
                                <Col span={24}>
                                    {handleDown && (
                                        <ArrowDownOutlined
                                            style={{ ...styles.icons, marginBottom: 0 }}
                                            onClick={() => handleDown(statement)}
                                            data-test={`statement${statement.key}Down`}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    )
}

const styles = {
    divider: {
        margin: 0,
    },
    tag: {
        marginBottom: 'var(--s-3)',
    },
    statementText: {
        marginBottom: 'var(--s0)',
    },
    iconRow: {
        margin: 'auto',
    },
    icons: {
        margin: 'var(--s-3)',
    },
}
