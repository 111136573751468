import { convert } from 'html-to-text'

export function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

export function truncate(str, length = 30) {
    if (!str) {
        return
    }
    if (str.length > length) {
        return str.substring(0, length) + '...'
    }

    return str
}

export const jsonCharacterCount = data => {
    try {
        let count = 0
        data.content.forEach(line => {
            line.content?.forEach(textItem => {
                if (textItem.type === 'text') {
                    count += textItem.text.length
                } else if (textItem.type === 'hardBreak') {
                    count += 2
                }
            })
        })
        count += (data.content.length - 1) * 2
        return count
    } catch (e) {
        return 0
    }
}

export const getCharacterCount = html => {
    const text = convert(html, { selectors: [{ selector: 'a', options: { ignoreHref: true } }] })
    return text.replace(/\s/g, '').length
}

export const clearHtmlFormatting = html => {
    // replace any <h1> tags with <p> tags
    html = html.replace(/<h1/g, '<p')
    html = html.replace(/<\/h1>/g, '</p>')
    // replace any <h2> tags with <p> tags
    html = html.replace(/<h2/g, '<p')
    html = html.replace(/<\/h2>/g, '</p>')
    // replace any <h3> tags with <p> tags
    html = html.replace(/<h3/g, '<p')
    html = html.replace(/<\/h3>/g, '</p>')
    // replace any <h4> tags with <p> tags
    html = html.replace(/<h4/g, '<p')
    html = html.replace(/<\/h4>/g, '</p>')
    // replace any <h5> tags with <p> tags
    html = html.replace(/<h5/g, '<p')
    html = html.replace(/<\/h5>/g, '</p>')
    // replace any <h6> tags with <p> tags
    html = html.replace(/<h6/g, '<p')
    html = html.replace(/<\/h6>/g, '</p>')
    // replace any <ul> tags with <p> tags
    html = html.replace(/<ul>/g, '')
    html = html.replace(/<\/ul>/g, '')
    // replace any <ol> tags with <p> tags
    html = html.replace(/<ol>/g, '')
    html = html.replace(/<\/ol>/g, '')
    // replace any <li> tags with <p> tags
    html = html.replace(/<li/g, '<p')
    html = html.replace(/<\/li>/g, '</p>')
    // replace any <a> tags with <p> tags
    html = html.replace(/<a\b[^>]*>/g, '')
    html = html.replace(/<\/a>/g, '')
    // remove any <strong> tags
    html = html.replace(/<strong>/g, '')
    html = html.replace(/<\/strong>/g, '')
    // remove any <em> tags
    html = html.replace(/<em>/g, '')
    html = html.replace(/<\/em>/g, '')
    // replace any <br> tags with <p> tags
    html = html.replace(/<br>/g, '<p></p>')
    html = html.replace(/<\/br>/g, '<p></p>')
    html = html.replace(/<br\/>/g, '<p></p>')
    html = html.replace(/<br \/>/g, '<p></p>')
    // change font weight to normal
    html = html.replace(/font-weight:bold;/g, 'font-weight: normal;')
    html = html.replace(/font-weight: bold;/g, 'font-weight: normal;')
    html = html.replace(/font-weight:700/g, 'font-weight: normal;')
    html = html.replace(/font-weight: 700/g, 'font-weight: normal;')

    // remove any empty <p> tags
    html = html.replace(/<p><\/p>/g, '')
    html = html.replace(/<p><p/g, '<p')
    html = html.replace(/<\/p><\/p>/g, '</p>')

    return html
}
