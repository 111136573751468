import React, { useState, useEffect } from 'react'

import { DatePicker, Row, Col } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { startOfDay, isBefore } from 'date-fns'
import moment from 'moment'

import { useDateFormat } from 'stores/UserStore'
import messages from 'services/intl/messageDefinitions'
import { sendErrorNotification } from 'utils/Notifications'
import { TIMEZONE_PACIFIC } from 'utils/Product'
import { convertBrowserTimeToServerTime, convertServerTimeToBrowserTime } from 'utils/Timezone'
import { Moment } from 'moment'

const ISSUED = 'issued'
const EXPIRY = 'expiry'

type RangePickerProps = {
    disabled: boolean
    onChange: (values: [Date, Date]) => void
    initValues: [Date, Date]
    dateRangeError: boolean
    timezone?: string
}

export const RangePicker = ({
    disabled,
    onChange,
    initValues,
    dateRangeError,
    timezone = TIMEZONE_PACIFIC,
}: RangePickerProps) => {
    const intl = useIntl()

    const [issueDateTime, setIssueDateTime] = useState(initValues[0])
    const [expiryDateTime, setExpiryDateTime] = useState(initValues[1])
    const [issuedOpened, setIssuedOpened] = useState(false)
    const [expiryOpened, setExpiryOpened] = useState(false)
    const dateFormat = useDateFormat()
    const today = startOfDay(new Date())

    const disabledStartDate = (date: Moment): boolean => {
        if (!date || !expiryDateTime) {
            return false && disabled
        }
        return isBefore(date.toDate(), today)
    }

    const disabledEndDate = (date: Moment): boolean => {
        if (!date || !issueDateTime) {
            return false && disabled
        }
        return isBefore(date.toDate(), today)
    }

    const showIssuedCloseError = () => {
        issuedOpened && sendErrorNotification(intl.formatMessage({ ...messages.dateSelectionError }))
    }

    const showExpireCloseError = () => {
        expiryOpened && sendErrorNotification(intl.formatMessage({ ...messages.dateSelectionError }))
    }

    useEffect(() => {
        setIssueDateTime(initValues[0])
        setExpiryDateTime(initValues[1])
    }, [initValues, setIssueDateTime, setExpiryDateTime])

    useEffect(() => {
        expiryOpened && setIssuedOpened(false)
    }, [expiryOpened])

    useEffect(() => {
        issuedOpened && setExpiryOpened(false)
    }, [issuedOpened])

    const handleDateChange = (dateMoment: Moment | null, key: 'issued' | 'expiry') => {
        if (!dateMoment) return
        const date = dateMoment.toDate()

        if (key === 'issued') {
            const zonedDate = convertBrowserTimeToServerTime(date, timezone)
            setIssueDateTime(zonedDate)
            onChange([zonedDate, expiryDateTime])
        }

        if (key === 'expiry') {
            const zonedDate = convertBrowserTimeToServerTime(date, timezone)
            setExpiryDateTime(zonedDate)
            onChange([issueDateTime, zonedDate])
        }
    }

    const displayIssueDateTime = moment(convertServerTimeToBrowserTime(issueDateTime, timezone))
    const displayExpiryDateTiem = moment(convertServerTimeToBrowserTime(expiryDateTime, timezone))

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <DatePicker
                        disabledDate={disabledStartDate}
                        showTime
                        minuteStep={15}
                        format={dateFormat + '   HH:mm'}
                        value={displayIssueDateTime}
                        onChange={value => handleDateChange(value, ISSUED)}
                        allowClear={false}
                        open={issuedOpened}
                        onClick={() => setIssuedOpened(!issuedOpened)}
                        onOk={() => setIssuedOpened(false)}
                        onBlur={() => showIssuedCloseError()}
                        disabled={disabled}
                        status={dateRangeError ? 'error' : ''}
                        data-testid="issued-date-picker"
                    />
                </Col>
                <Col span={12}>
                    <DatePicker
                        disabledDate={disabledEndDate}
                        showTime
                        minuteStep={15}
                        format={dateFormat + '   HH:mm'}
                        value={displayExpiryDateTiem}
                        onChange={value => handleDateChange(value, EXPIRY)}
                        allowClear={false}
                        open={expiryOpened}
                        onClick={() => setExpiryOpened(!expiryOpened)}
                        onOk={() => setExpiryOpened(false)}
                        onBlur={() => showExpireCloseError()}
                        disabled={disabled}
                        status={dateRangeError ? 'error' : ''}
                        data-testid="expiry-date-picker"
                    />
                </Col>
            </Row>
            {dateRangeError && (
                <p style={styles.error}>
                    <FormattedMessage {...messages.invalidDateRange} />
                </p>
            )}
        </>
    )
}

const styles = {
    error: {
        color: '#a9222b',
        fontSize: '14px',
        marginBottom: '-10px',
    },
}
