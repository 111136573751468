import { createNewImage } from 'utils/Image'

export const addImage = (product, locale = 'en', tag) => {
    return {
        ...product,
        media: {
            ...product.media,
            images: [...product.media.images, createNewImage(locale, tag)],
        },
    }
}
export const removeImage = (product, imageId) => {
    return {
        ...product,
        media: {
            ...product.media,
            images: product.media.images.filter(image => image.id !== imageId),
        },
    }
}

export const updateImageField = (product, imageId, fields) => {
    return {
        ...product,
        isAutoTranslated: false,
        media: {
            ...product.media,
            images: product.media.images.map(image => {
                return image.id === imageId ? { ...image, ...fields } : image
            }),
        },
    }
}

export const updateImageCaption = (product, imageId, language, value) => {
    return {
        ...product,
        isAutoTranslated: false,
        media: {
            ...product.media,
            images: product.media.images.map(image => {
                return image.id === imageId ? { ...image, caption: { ...image.caption, [language]: value } } : image
            }),
        },
    }
}

export const addTranslationToImageCaption = (product, imageId, language) => {
    return {
        ...product,
        media: {
            ...product.media,
            images: product.media.images.map(image => {
                return image.id === imageId ? { ...image, caption: { ...image.caption, [language]: '' } } : image
            }),
        },
    }
}

export const removeTranslationFromImageCaption = (product, imageId, language) => {
    return {
        ...product,
        isAutoTranslated: false,
        media: {
            ...product.media,
            images: product.media.images.map(image => {
                if (image.id === imageId) {
                    const remainingTranslations = {}
                    for (let key in image.caption) {
                        if (key !== language) {
                            remainingTranslations[key] = image.caption[key]
                        }
                    }

                    return {
                        ...image,
                        caption: remainingTranslations,
                    }
                }

                return image
            }),
        },
    }
}

export const removeTranslationFromImageAltText = (product, imageId, language) => {
    return {
        ...product,
        isAutoTranslated: false,
        media: {
            ...product.media,
            images: product.media.images.map(image => {
                if (image.id === imageId) {
                    const remainingTranslations = {}
                    for (let key in image.altText) {
                        if (key !== language) {
                            remainingTranslations[key] = image.altText[key]
                        }
                    }

                    return {
                        ...image,
                        altText: remainingTranslations,
                    }
                }

                return image
            }),
        },
    }
}

export const switchTwoImages = (product, image1, image2) => {
    const images = product.media.images

    const image1Index = images.indexOf(image1)
    const image2Index = images.indexOf(image2)

    images[image1Index] = image2
    images[image2Index] = image1

    return {
        ...product,
        media: {
            ...product.media,
            images: [...images],
        },
    }
}

export const supportedUrl = url => {
    return SUPPORTED_FILE_TYPES.includes(url.split('.').pop().toLowerCase())
        ? url
        : url.substr(0, url.lastIndexOf('.')) + '.jpg'
}

const SUPPORTED_FILE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp']

// Must be a valid Cloudinary URL
export const isValidUrl = url => {
    // Check that it has a cloudinary domain
    const regex = /^https?:\/\/res\.cloudinary\.com\/.*\/image\/upload\/.*$/
    if (!regex.test(url)) return false

    // Check that it has a valid image extension
    // const extensionRegex = /(\.jpg|\.jpeg|\.png|\.gif|\.svg)$/
    // if (!extensionRegex.test(url)) return false

    return true
}
