import { Modal } from 'antd'
import { FormattedMessage } from 'react-intl'

import messages from 'services/intl/messageDefinitions'

type DeleteTranslationConfirmationModalProps = {
    visible: boolean
    removeHandler: (editorContent: string, data: any) => void
    setVisible: (visible: boolean) => void
    editorContent: string
    data: any
}

export const DeleteTranslationConfirmationModal = ({
    visible,
    removeHandler,
    setVisible,
    editorContent,
    data,
}: DeleteTranslationConfirmationModalProps) => {
    const handleOk = () => {
        removeHandler(editorContent, data)
        setVisible(false)
    }

    return (
        <Modal
            title={<FormattedMessage {...messages.warning} />}
            visible={visible}
            onOk={() => handleOk()}
            onCancel={() => setVisible(false)}
            okText={<FormattedMessage {...messages.delete} />}
            cancelText={<FormattedMessage {...messages.cancel} />}
        >
            <FormattedMessage {...messages.deleteTranslationConfirmation} />{' '}
        </Modal>
    )
}
