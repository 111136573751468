import React, { useEffect, useState } from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Row, Col, Modal, Button } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { uuid } from 'utils/String'
import { DANGER_RATING_OPTIONS } from 'utils/ForecastContent'
import { DEFAULT_VALUE_DANGER_RATINGS as DEFAULT } from 'utils/Constants'
import { noop } from 'utils/function'
import messages from 'services/intl/messageDefinitions'
import { useContent, useSetContent } from 'stores/ForecastStore'
import { EditScreenLayout } from 'components/EditScreenLayout/EditScreenLayout'
import { DayCardLayout } from 'components/DayCardLayout/DayCardLayout'
import { DayCard } from 'components/DayCard/DayCard'
import { DangerRatingTable } from 'components/Documentation/DangerRatingTable'
import { ProblemDisplay } from 'components/AvalancheForecast/Content/AvalancheProblems/ProblemDisplay'
import { Select } from 'components/Dropdown/Select'
import { useUndo } from 'components/AvalancheForecast/Content/useUndo'
import { FloatMenu } from 'components/AvalancheForecast/Content/FloatMenu'

const VIEW = 'dangerRatings'

export const DangerRatings = ({ update, dayDetails, setReviewDrawerVisible, preview, compressed, colWidth }) => {
    const [cards, setCards] = useState([])
    const [updateFields, setUpdateFields] = useState(false)
    const [cloneNext, setCloneNext] = useState(false)
    const [cloneAll, setCloneAll] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [modalVisible, setModalVisibility] = useState(false)
    const content = useContent()
    const setContent = useSetContent()
    const [form] = Form.useForm()
    const title = <FormattedMessage {...messages.dangerRatings} />
    const intl = useIntl()
    const { undoUpdate, initUndo } = useUndo()

    const actionHandlers = {
        cloneNext: day => setCloneNext(day.position),
        cloneAll: day => setCloneAll(day.position),
        reviewNote: () => setReviewDrawerVisible(true),
        preview: () => preview(),
    }

    // create array that contains objects that have all day info including content
    const structureDayData = (data, avalancheProblems) => {
        return dayDetails.map((detail, d) => {
            const dayIndex = data.findIndex(el => el.position === detail.position)
            let dataDay = { alp: DEFAULT, tln: DEFAULT, btl: DEFAULT }
            if (dayIndex !== -1) {
                dataDay = data[dayIndex]
            }

            const avalancheProblemsDayIndex = avalancheProblems.findIndex(el => el.position === detail.position)
            let avalancheProblemsDay = []
            if (avalancheProblemsDayIndex !== -1) {
                avalancheProblemsDay = avalancheProblems[avalancheProblemsDayIndex].problems || []
            }

            return {
                // DayCard Setup
                value: d + 1,
                type: d === 0 ? 'Nowcast' : 'Forecast',
                day: detail.day,
                date: detail.date,

                // DayCard Content
                position: d,
                alp: dataDay.alp,
                tln: dataDay.tln,
                btl: dataDay.btl,
                avalancheProblems: avalancheProblemsDay,
            }
        })
    }

    const undo = async () => {
        const undoResult = await undoUpdate({ versionNumber: content.versionNumber, content, section: VIEW })
        if (undoResult) {
            setContent(undoResult)
            const dangerRatings = undoResult.dangerRatings ? undoResult.dangerRatings : []
            const avalancheProblems = undoResult.avalancheProblems?.days ? undoResult.avalancheProblems.days : []
            const structured = structureDayData(dangerRatings, avalancheProblems)
            setCards(generateDayCards(structured))
        }
    }

    // Generate options for danger ratings select
    const dangerRatingsOptions = [...DANGER_RATING_OPTIONS].map((item, i) => {
        return (
            <Select.Option key={i} value={item.value}>
                <div style={styles.menuRow}>
                    <span data-test={`dangerRating-${item.label}`} style={styles.iconSpan}>
                        <img width="30" height="30" alt={item.value} src={`/assets/danger-ratings/${item.icon}.svg`} />
                    </span>
                    <FormattedMessage {...messages[item.label]} />
                </div>
            </Select.Option>
        )
    })

    // Update form values based on day content
    const updateFormValues = (day, i) => {
        form.setFieldsValue({
            [`alp${i}`]: day.alp,
            [`tln${i}`]: day.tln,
            [`btl${i}`]: day.btl,
        })
    }

    const generateDayCards = structuredDays => {
        return structuredDays.map((day, i) => {
            // Update form values based on day content
            updateFormValues(day, i)

            // Handles any changes made and sends API call and updates store
            const handleChange = changedValue => {
                const index = Object.keys(changedValue)[0].slice(-1)
                const elevation = Object.keys(changedValue)[0].slice(0, -1)
                const val = Object.values(changedValue)[0]

                setUpdateFields({ index, elevation, val })
            }

            const editor = (
                <>
                    <Form form={form} layout="horizontal" onValuesChange={handleChange}>
                        <Row>
                            <Col span={10} style={styles.label}>
                                <p style={styles.labelText}>
                                    <FormattedMessage {...messages.alpine} />
                                </p>
                            </Col>
                            <Col span={14} style={styles.selectCol}>
                                <Form.Item
                                    name={`alp${i}`}
                                    style={styles.formItem}
                                    aria-label={`${intl.formatMessage({ ...messages.alpine })} ${intl.formatMessage({
                                        ...messages.day,
                                    })} ${i + 1}`}
                                >
                                    <Select style={styles.select} size={'large'} bordered={false} listHeight="100%">
                                        {dangerRatingsOptions}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={10} style={styles.label}>
                                <p style={styles.labelText}>
                                    <FormattedMessage {...messages.treeline} />
                                </p>
                            </Col>
                            <Col span={14} style={styles.selectCol}>
                                <Form.Item
                                    name={`tln${i}`}
                                    style={styles.formItem}
                                    aria-label={`${intl.formatMessage({ ...messages.treeline })} ${intl.formatMessage({
                                        ...messages.day,
                                    })} ${i + 1}`}
                                >
                                    <Select style={styles.select} size={'large'} bordered={false} listHeight="100%">
                                        {dangerRatingsOptions}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={10} style={styles.label}>
                                <p style={styles.labelText}>
                                    <FormattedMessage {...messages.belowTreeLine} />
                                </p>
                            </Col>
                            <Col span={14} style={styles.selectCol}>
                                <Form.Item
                                    name={`btl${i}`}
                                    style={styles.formItem}
                                    aria-label={`${intl.formatMessage({
                                        ...messages.belowTreeLine,
                                    })} ${intl.formatMessage({
                                        ...messages.day,
                                    })} ${i + 1}`}
                                >
                                    <Select style={styles.select} size={'large'} bordered={false} listHeight="100%">
                                        {dangerRatingsOptions}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <QuestionCircleOutlined style={styles.infoIcon} onClick={() => setModalVisibility(true)} />
                    {day.avalancheProblems && <div style={styles.problemBorder}></div>}
                    {day.avalancheProblems &&
                        day.avalancheProblems.map(problem => {
                            return <ProblemDisplay key={problem.id} problem={problem} />
                        })}
                </>
            )

            return (
                <DayCard
                    day={day}
                    key={uuid()}
                    cardContent={editor}
                    actionHandlers={actionHandlers}
                    dataProvider={noop}
                    editable={compressed ? false : true}
                />
            )
        })
    }

    useEffect(() => {
        if (!dataLoaded && Object.keys(content).length > 0 && dayDetails.length > 0) {
            setDataLoaded(true)
            const dangerRatings = content.dangerRatings ? content.dangerRatings : []
            const avalancheProblems = content.avalancheProblems?.days ? content.avalancheProblems.days : []
            const structured = structureDayData(dangerRatings, avalancheProblems)
            setCards(generateDayCards(structured))
        }
    }, [content, dayDetails])

    // TODO: we shouldn't need this with proper instantiation when creating a new forecast
    const generateNewDays = content => {
        if (content.dangerRatings?.length > 0) {
            return content.dangerRatings
        }

        return content.forecastDays.map((day, i) => {
            return {
                position: i,
                alp: DEFAULT,
                tln: DEFAULT,
                btl: DEFAULT,
            }
        })
    }

    // Update Danger Ratings
    useEffect(() => {
        if (updateFields !== false) {
            setUpdateFields(false)

            const newDays = generateNewDays(content).map((day, i) => {
                return day.position === parseInt(updateFields.index)
                    ? { ...day, [updateFields.elevation]: updateFields.val }
                    : day
            })

            initUndo(content, VIEW)
            // send API update
            update({ ...content, dangerRatings: newDays }, VIEW)
        }
    }, [content, updateFields])

    // Clone to Next Day
    useEffect(() => {
        if (cloneNext !== false) {
            setCloneNext(false)

            let newDays = generateNewDays(content)
            const dayToBeCloned = newDays[cloneNext]
            newDays[cloneNext + 1] = { ...dayToBeCloned, position: newDays[cloneNext + 1].position }

            initUndo(content, VIEW)
            // send API update
            update({ ...content, dangerRatings: newDays }, VIEW)

            // regenerate cards
            const avalancheProblems = content.avalancheProblems?.days ? content.avalancheProblems.days : []
            const structured = structureDayData(newDays, avalancheProblems)
            setCards(generateDayCards(structured))
        }
    }, [content, cloneNext])

    // Clone to All Days
    useEffect(() => {
        if (cloneAll !== false) {
            setCloneAll(false)

            let newDays = generateNewDays(content)
            const { alp, tln, btl } = newDays[cloneAll]
            newDays = newDays.map(day => {
                return { ...day, alp, tln, btl }
            })

            initUndo(content, VIEW)
            // send API update
            update({ ...content, dangerRatings: newDays }, VIEW)

            // regenerate cards
            const avalancheProblems = content.avalancheProblems?.days ? content.avalancheProblems.days : []
            const structured = structureDayData(newDays, avalancheProblems)
            setCards(generateDayCards(structured))
        }
    }, [content, cloneAll])

    const footer = (
        <Button type="primary" onClick={() => setModalVisibility(false)}>
            <FormattedMessage {...messages.close} />
        </Button>
    )

    return (
        <>
            <EditScreenLayout dayDetails={dayDetails} title={title} compressed={compressed} colWidth={colWidth}>
                <DayCardLayout dayCards={cards} />
                <FloatMenu undoHandler={undo} />
            </EditScreenLayout>
            <Modal
                centered
                visible={modalVisible}
                cancelText={'close'}
                onCancel={() => setModalVisibility(false)}
                onOk={() => setModalVisibility(false)}
                width={'90%'}
                footer={footer}
            >
                <DangerRatingTable />
            </Modal>
        </>
    )
}

const styles = {
    menuRow: {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row nowrap',
        overflow: 'hidden',
    },
    iconSpan: {
        marginRight: 'var(--s-2)',
        flexShrink: 0,
    },
    label: {
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid var(--grey-light)',
        borderLeft: 'none',
    },
    labelText: {
        marginBottom: 0,
    },
    selectCol: {
        borderTop: '1px solid var(--grey-light)',
        borderBottom: '1px solid var(--grey-light)',
    },
    formItem: {
        marginBottom: 0,
    },
    select: {
        width: '100%',
    },
    infoIcon: {
        margin: 'var(--s-1) 0 var(--s0) var(--s-1)',
        cursor: 'pointer',
    },
    problemBorder: {
        marginBottom: 'var(--s-1)',
        borderTop: '1px solid var(--grey-light)',
    },
}
