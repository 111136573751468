import { EN, FR } from 'utils/Locales'
import { postTranslation } from 'services/Translations'

const SUMMARY_SECTIONS = ['weatherSummary', 'avalancheSummary', 'snowpackSummary']

// translateForecast - translates the forecast for the given untranslated fields
export const translateForecast = async (item, forecasterLanguage) => {
    try {
        const langTo = forecasterLanguage === EN ? FR : EN

        const stringsToBeTranslated = getFieldsToTranslate(item, forecasterLanguage)
        const stringsArray = stringsToBeTranslated.map(string => string.text)
        const translatedStrings = await postTranslation(stringsArray, langTo)
        const newForecast = applyTranslatedStrings({ forecast: item, langTo, stringsToBeTranslated, translatedStrings })

        return newForecast
    } catch (error) {
        console.error('Error translating forecast', error)
        return false
    }
}

// getFieldsToTranslate - returns an array of objects to indicate which fields need to be translated
// {section: 'weatherSummary', day: 0, text: '<html>...</html>'}
// {section: 'avalancheProblems', day: 0, problem: 0, text: '<html>...</html>'}
const getFieldsToTranslate = (item, forecasterLanguage) => {
    const stringsToBeTranslated = []

    // Summary sections
    for (const section of SUMMARY_SECTIONS) {
        if (!item[section] || !item[section].days) continue

        // Loop through each day and get any text that isn't empty
        for (const [dayIndex, day] of item[section].days.entries()) {
            if (!day.content[forecasterLanguage]) continue

            stringsToBeTranslated.push({
                section,
                day: dayIndex,
                text: day.content[forecasterLanguage],
            })
        }
    }

    // Communications sections
    if (item.communications.headline[forecasterLanguage]) {
        stringsToBeTranslated.push({
            section: 'headline',
            text: item.communications.headline[forecasterLanguage],
        })
    }
    if (item.communications.sms[forecasterLanguage]) {
        stringsToBeTranslated.push({
            section: 'sms',
            text: item.communications.sms[forecasterLanguage],
        })
    }

    // Avalanche problems section
    for (const [dayIndex, day] of item.avalancheProblems.days.entries()) {
        if (!day.problems || !day.problems.length) continue

        for (const [problemIndex, problem] of day.problems.entries()) {
            if (!problem.comment[forecasterLanguage]) continue

            stringsToBeTranslated.push({
                section: 'avalancheProblems',
                day: dayIndex,
                problem: problemIndex,
                text: problem.comment[forecasterLanguage],
            })
        }
    }

    // Media
    for (const image of item.media.images) {
        if (image.altText[forecasterLanguage]) {
            stringsToBeTranslated.push({
                section: 'altText',
                imageId: image.id,
                text: image.altText[forecasterLanguage],
            })
        }

        if (image.caption[forecasterLanguage]) {
            stringsToBeTranslated.push({
                section: 'caption',
                imageId: image.id,
                text: image.caption[forecasterLanguage],
            })
        }
    }

    return stringsToBeTranslated
}

// applyTranslatedStrings - returns a new forecast with the translated strings applied
const applyTranslatedStrings = ({ forecast, langTo, stringsToBeTranslated, translatedStrings }) => {
    const newForecast = { ...forecast, isAutoTranslated: true }

    // Update the forecast
    stringsToBeTranslated.forEach((sectionObject, index) => {
        const { section } = sectionObject

        // Summary sections
        if (SUMMARY_SECTIONS.includes(section)) {
            const { day } = sectionObject
            newForecast[section].days[day].content[langTo] = translatedStrings.data[index].text
        }

        // Communications sections
        if (section === 'headline' || section === 'sms') {
            newForecast.communications[section][langTo] = translatedStrings.data[index].text
        }

        // Avalanche problems section
        if (section === 'avalancheProblems') {
            const { day, problem } = sectionObject
            newForecast.avalancheProblems.days[day].problems[problem].comment[langTo] =
                translatedStrings.data[index].text
        }

        // Media
        if (section === 'altText') {
            const { imageId } = sectionObject
            const imageIndex = newForecast.media.images.findIndex(image => image.id === imageId)
            newForecast.media.images[imageIndex].altText[langTo] = translatedStrings.data[index].text
        }
        if (section === 'caption') {
            const { imageId } = sectionObject
            const imageIndex = newForecast.media.images.findIndex(image => image.id === imageId)
            newForecast.media.images[imageIndex].caption[langTo] = translatedStrings.data[index].text
        }
    })

    return newForecast
}
