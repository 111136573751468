import { DEFAULT_VALUE_DANGER_RATINGS, DEFAULT_VALUE_CONFIDENCE, DEFAULT_VALUE_REVIEW } from './Constants'
import { createEmptyProduct } from './Product'
import { getCharacterCount } from './String'

// TODO: dynamic language
export const createEmptyAvalancheForecast = (locale = 'en') => {
    return {
        ...createEmptyProduct('avalancheforecast', locale),
        weatherSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        snowpackSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        avalancheSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        avalancheProblems: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i }
            }),
        },
        // TODO: coordinate changing this over to days array with backend
        dangerRatings: new Array(4).fill().map((el, i) => {
            return {
                position: i,
                alp: DEFAULT_VALUE_DANGER_RATINGS,
                tln: DEFAULT_VALUE_DANGER_RATINGS,
                btl: DEFAULT_VALUE_DANGER_RATINGS,
            }
        }),
        confidence: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, rating: DEFAULT_VALUE_CONFIDENCE, statements: [] }
            }),
        },
        communications: {
            headline: {
                [locale]: '',
            },
            sms: {
                [locale]: '',
            },
        },
        review: {
            status: DEFAULT_VALUE_REVIEW,
            notes: '',
        },
    }
}

export const DAY_ONE = new Set(['issueDate', 'issueDatePlusOne'])

// this will only work with [{date: ..., content: ...}]
export const unstructureDayData = daysList => {
    return daysList.map(day => {
        return {
            date: day.date,
            content: day.content,
        }
    })
}

// create array that contains objects that have all day info including content
// this will only work with [{date: ..., content: ...}]
export const structureDayData = (data, dayDetails) => {
    const structuredDays = dayDetails.map((detail, d) => {
        const dayIndex = data.findIndex(el => el.date === detail.date)
        const dataDay = data[dayIndex]
        const current = dataDay ? dataDay : { content: { en: '' }, date: detail.date }
        return {
            date: current.date,
            content: current.content,
            value: d + 1,
            day: detail.day,
            type: d === 0 ? 'Nowcast' : 'Forecast',
        }
    })

    return structuredDays
}

// Go through each potential character limit and check if it is exceeded
export const checkAllCharLimits = (data, charLimits) => {
    let limitsExceeded = []

    // Summary sections
    const summarySections = ['weatherSummary', 'snowpackSummary', 'avalancheSummary']
    summarySections.forEach(section => {
        if (charLimits[section]) {
            // Check each day
            for (let day in data[section]?.days) {
                if (limitsExceeded.includes(section)) {
                    break
                }

                // Check english and french
                for (let lang in charLimits[section]) {
                    const charCount = getCharacterCount(data[section]?.days[day].content[lang])
                    if (charCount > charLimits[section][lang]) {
                        limitsExceeded.push(section)
                    }
                }
            }
        }
    })

    // Headline
    if (charLimits.headline && data.communications.headline) {
        // Check english and french
        for (let lang in charLimits.headline) {
            const charCount = getCharacterCount(data.communications.headline[lang])
            if (charCount > charLimits.headline[lang]) {
                limitsExceeded.push('communications')
            }
        }
    }

    // Avalanche problems
    // This has been removed because you can't save a problem if it exceeds the character limit
    // if (charLimits.problemDiscussion) {
    //     // Check each day
    //     for (let day in data.avalancheProblems.days) {
    //         if (limitsExceeded.includes('avalancheProblems')) {
    //             break
    //         }

    //         // Check english and french
    //         for (let lang in charLimits.problemDiscussion) {
    //             // Check if the character limit is exceeded
    //             for (let problem in data.avalancheProblems.days[day].problems) {
    //                 const charCount = getCharacterCount(data.avalancheProblems.days[day].problems[problem][lang])
    //                 if (charCount > charLimits.problemDiscussion[lang]) {
    //                     limitsExceeded.push('avalancheProblems')
    //                 }
    //             }
    //         }
    //     }
    // }

    return limitsExceeded
}
