import useSWRImmutable from 'swr/immutable'

import { fetchSettings } from 'services/Settings'

export const useSettings = () => {
    const key = ['settings']

    return useSWRImmutable(key, () => fetchSettings())
}

export const useCharacterLimits = () => {
    const { data } = useSettings()

    return data?.data?.characterLimits ? data.data.characterLimits : {}
}
