import React from 'react'

import { FormattedMessage } from 'react-intl'

import messages from 'services/intl/messageDefinitions'
import { useDateFormat } from 'stores/UserStore'
import { formatDate } from 'utils/Date'

type Tuple = {
    from: string | number | Date
    to: string | number | Date
}

type StatProps = {
    label: string
    value: string | number | Date | Tuple | React.ReactNode
    type: 'date' | 'text' | 'tuple'
    unit?: string
    time?: boolean
    size?: 'small' | 'medium'
    labelStyles?: React.CSSProperties
    valueStyles?: React.CSSProperties
}

export const Stat = ({ label, value, type, unit, time, size, labelStyles, valueStyles }: StatProps) => {
    const storeFormat = useDateFormat()
    const dateFormat = time ? storeFormat + ', HH:mm' : storeFormat
    const labelSize = size === 'small' ? '10px' : '14px'
    const valueSize = size === 'small' ? '20px' : '25px'

    // TODO: we should check for value === null here

    let formattedValue: string | React.ReactNode
    if (React.isValidElement(value)) {
        formattedValue = value
    } else if (type === 'date') {
        formattedValue = formatDate(value, dateFormat)
    } else if (value && type === 'text') {
        const typeSafeValue = value as unknown as string
        formattedValue =
            typeSafeValue in messages ? (
                <FormattedMessage {...messages[typeSafeValue as keyof typeof messages]} />
            ) : (
                typeSafeValue
            )
    } else if (type === 'tuple') {
        const tuple = value as Tuple
        formattedValue = `${tuple.from}${unit ?? ''} - ${tuple.to}${unit ?? ''}`
    }

    return (
        <div>
            <p style={{ ...styles.label, fontSize: labelSize, ...labelStyles }}>
                {label in messages ? <FormattedMessage {...messages[label as keyof typeof messages]} /> : label}
            </p>
            <h5 style={{ ...styles.value, fontSize: valueSize, ...valueStyles }}>{formattedValue}</h5>
        </div>
    )
}

const styles = {
    label: {
        color: 'var(--grey)',
        marginBottom: '0px',
        textTransform: 'capitalize' as const,
        fontWeight: '600',
    },
    value: {
        marginTop: '0px',
        textTransform: 'capitalize' as const,
        fontWeight: '400',
    },
}
