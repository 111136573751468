import { Alert, Button, Checkbox, Col, Divider, Progress, Row, Tag, Typography } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import { Date as DateDisplay } from 'components/Date/Date'
import { DeleteButton } from 'components/DeleteButton/DeleteButton'
import { PolygonList } from 'components/PolygonList/PolygonList'
import { PreviewButton } from 'components/Product/PreviewButton'
import { PublishedProductReviewCheck } from 'components/PublishedProductReviewCheck/PublishedProductReviewCheck'
import { Stat } from 'components/Stat/Stat'
import { Spacer } from 'components/VerticalSpacer/Spacer'
import { usePolygons } from 'hooks/polygons'
import { useSettings } from 'hooks/Settings'
import { AvFxContent } from 'pages/AvalancheForecast/AvFxContent'
import { fetchAvalancheForecast } from 'services/AvalancheForecast'
import messages from 'services/intl/messageDefinitions'
import { getColourOfReviewStatus, getReviewStatusTextColour, progressToPercent } from 'utils/ForecastContent'
import { convertServerTimeToBrowserTime } from 'utils/Timezone'
import { MultiDayTooltip } from './MultiDayTooltip'

export const DetailsContent = ({
    item,
    detailsColWidth,
    disablePublish,
    toggleEdit,
    cloneForecast,
    publish,
    setDeleteModalVisible,
    toggleTranslated,
    showContent,
    mutate,
    locale,
    handlePreviewClick,
    autoTranslateForecast,
}) => {
    const intl = useIntl()
    const { Title } = Typography

    const polygons = usePolygons()
    const { data: settings } = useSettings()
    const featureFlags = settings?.data?.featureFlags || {}
    const { autoTranslate, showTimezoneSelector, showTranslationCheckbox } = featureFlags

    return (
        <div style={styles.detailsBody}>
            <Title level={2} style={styles.title}>
                {item.name}
            </Title>
            <div style={styles.header}>
                <div>{item.username}</div>
                <DateDisplay label={'lastModified'} time={true} value={item.lastModifiedDateTime} />
            </div>
            <div style={styles.detailsContainer}>
                <Divider orientation="left" plain>
                    <FormattedMessage {...messages.details} />
                </Divider>
                <Row gutter={8}>
                    <Col style={styles.detailCol} span={detailsColWidth}>
                        <Stat
                            label={'issueDateTime'}
                            value={convertServerTimeToBrowserTime(new Date(item.issueDateTime), item.timezone)}
                            type={'date'}
                            time={true}
                        />
                    </Col>
                    <Col style={styles.detailCol} span={detailsColWidth}>
                        <Stat
                            label={'expiryDateTime'}
                            value={convertServerTimeToBrowserTime(new Date(item.expiryDateTime), item.timezone)}
                            type={'date'}
                            time={true}
                        />
                    </Col>
                    <Col style={styles.detailCol} span={detailsColWidth}>
                        <Stat label={'forecastDayOne'} value={<FormattedMessage {...messages[item.dayOne]} />} />
                    </Col>
                    <Col span={detailsColWidth}>
                        {item.review && item.review.status && (
                            <Stat
                                label={'reviewStatus'}
                                value={
                                    <Tag
                                        color={getColourOfReviewStatus(item.review.status)}
                                        style={{ color: getReviewStatusTextColour(item.review.status) }}
                                    >
                                        <FormattedMessage {...messages[item.review.status]} />
                                    </Tag>
                                }
                            />
                        )}
                    </Col>
                    {settings?.data?.featureFlags?.multiDayForecasts && (
                        <Col span={detailsColWidth}>
                            <Stat
                                label={
                                    <>
                                        <FormattedMessage {...messages.multiDayForecast} />
                                        <MultiDayTooltip />
                                    </>
                                }
                                value={
                                    <>
                                        {item.isMultiDay ? (
                                            <FormattedMessage {...messages.yes} />
                                        ) : (
                                            <FormattedMessage {...messages.no} />
                                        )}
                                    </>
                                }
                            />
                        </Col>
                    )}
                    {showTimezoneSelector && (
                        <Col span={detailsColWidth}>
                            <Stat label={'timezone'} value={item.timezone} type={'text'} />
                        </Col>
                    )}
                    {showTranslationCheckbox && (
                        <Col span={detailsColWidth} style={styles.translatedCheckboxContainer}>
                            <Checkbox
                                label={intl.formatMessage({ ...messages.translated })}
                                checked={item.isTranslated}
                                onChange={toggleTranslated}
                                data-test="translatedCheckbox"
                                style={styles.translatedCheckbox}
                                disabled={item.status !== 'draft'}
                            >
                                <FormattedMessage {...messages.translated} />
                            </Checkbox>
                        </Col>
                    )}
                    <Col span={detailsColWidth}>
                        <Stat label={'progress'} value={<Progress percent={progressToPercent(item.progress)} />} />
                    </Col>
                </Row>
                <PolygonList polygonList={item.polygons} colour={item.colour} polygons={polygons} />
                {disablePublish && (
                    <Alert
                        style={styles.disablePublishAlert}
                        message={<FormattedMessage {...messages.disablePublishAlert} />}
                        type="error"
                    />
                )}
            </div>
            {item.status === 'draft' && (
                <>
                    <Row style={styles.buttonContainer}>
                        <Button onClick={toggleEdit} data-test={'forecastEditButton'}>
                            <FormattedMessage {...messages.editSetup} />
                        </Button>
                        <Button
                            onClick={() => window.open(`/avalanche-forecast-content?focus=${item.id}`, '_blank')}
                            disabled={!item.userID}
                            title={intl.formatMessage({ ...messages.missingForecaster })}
                            data-test="editContentButton"
                            data-test-forecast={item.id}
                        >
                            <FormattedMessage {...messages.editContent} />
                        </Button>
                        <Button onClick={() => cloneForecast(item, locale)} data-test={'cloneForecastButton'}>
                            <FormattedMessage {...messages.cloneToDraft} />
                        </Button>
                        {autoTranslate && (
                            <Button
                                onClick={() => autoTranslateForecast(item, locale)}
                                disabled={item.isAutoTranslated}
                            >
                                <FormattedMessage {...messages.autoTranslateForecast} />
                            </Button>
                        )}

                        <PreviewButton id={item.id} handlePreviewClick={handlePreviewClick} />
                        <Button type="primary" onClick={() => publish(item)} disabled={disablePublish}>
                            <FormattedMessage {...messages.publish} />
                        </Button>
                    </Row>
                    <DeleteButton setDeleteModalVisible={setDeleteModalVisible} buttonText={'deleteForecast'} />
                </>
            )}
            {(item.status === 'completed' || item.status === 'live') && (
                <>
                    <PublishedProductReviewCheck product={item} mutate={mutate} />
                    <Row style={styles.buttonContainer}>
                        <Button onClick={() => cloneForecast(item, locale)}>
                            <FormattedMessage {...messages.cloneToDraft} />
                        </Button>
                        <PreviewButton id={item.id} />
                    </Row>
                    <DeleteButton setDeleteModalVisible={setDeleteModalVisible} buttonText={'deleteForecast'} />
                </>
            )}

            <Spacer />
            {showContent && (
                <AvFxContent compressed colWidth={detailsColWidth} fetchForecast={fetchAvalancheForecast} />
            )}
        </div>
    )
}

const styles = {
    detailsBody: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
    },
    title: {
        fontWeight: '400',
        flex: '0 1 auto',
    },
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 'var(--s-1)',
        justifyContent: 'space-between',
        flex: '0 1 auto',
    },
    detailsContainer: {
        flex: '0 1 auto',
    },
    detailCol: {
        marginBottom: 'var(--s0)',
    },
    statusGrid: {
        marginBottom: 'var(--s0)',
    },
    buttonContainer: {
        marginTop: 'var(--s1)',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 'var(--s0)',
        flex: '0 1 auto',
    },
    translatedCheckboxContainer: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    translatedCheckbox: {
        marginBottom: 'var(--s1)',
    },
    disablePublishAlert: {
        marginTop: 'var(--s0)',
    },
}
