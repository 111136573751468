import { setHours, setMinutes, setSeconds, startOfDay } from 'date-fns'
import { convertBrowserTimeToServerTime } from './Timezone'

// Products with the same generic structure, including avalancheForecasts, regionalDiscussions, and specialProducts

// Allowed colours for these generic products. Excludes colours similar to the danger ratings (black, red, orange, yellow, green) as well as grey/white. TODO: make these colour-blind friendly
export const COLOUR_MAP = new Map([
    ['#FF7F50', 'coral'],
    ['#00FFFF', 'cyan'],
    ['#7FFFD4', 'aquamarine'],
    ['#DEB887', 'burlywood'],
    ['#B8860B', 'darkGoldenrod'],
    ['#00008B', 'darkBlue'],
    ['#008B8B', 'darkCyan'],
    ['#8B008B', 'darkMagenta'],
    ['#BDB76B', 'darkKhaki'],
    ['#FF1493', 'deepPink'],
    ['#CD5C5C', 'indianRed'],
    ['#20B2AA', 'lightSeaGreen'],
    ['#DA70D6', 'orchid'],
    ['#9ACD32', 'yellowGreen'],
    ['#D2B48C', 'tan'],
    ['#EE82EE', 'violet'],
    ['#A0522D', 'sienna'],
])
export const COLOUR_NAMES = Array.from(COLOUR_MAP, ([_, value]) => value)
export const COLOUR_HEXES = Array.from(COLOUR_MAP, ([value, _]) => value)

export const TIMEZONE_PACIFIC = 'America/Vancouver'
export const TIMEZONE_MOUNTAIN_CA = 'America/Edmonton'
export const TIMEZONE_MOUNTAIN_US = 'America/Denver'
export const TIMEZONE_EASTERN = 'America/New_York'
export const TIMEZONE_NEWFOUNDLAND = 'America/St_Johns'

// TODO: do we want translated labels?
export const TIMEZONE_OPTIONS = {
    [TIMEZONE_PACIFIC]: { translationId: 'timezone.pacific', value: TIMEZONE_PACIFIC },
    [TIMEZONE_MOUNTAIN_CA]: { translationId: 'timezone.mountain.ca', value: TIMEZONE_MOUNTAIN_CA },
    [TIMEZONE_MOUNTAIN_US]: { translationId: 'timezone.mountain.us', value: TIMEZONE_MOUNTAIN_US },
    [TIMEZONE_EASTERN]: { translationId: 'timezone.eastern', value: TIMEZONE_EASTERN },
    [TIMEZONE_NEWFOUNDLAND]: { translationId: 'timezone.newfoundland', value: TIMEZONE_NEWFOUNDLAND },
}

export const PRODUCT_TYPE_AVALANCHEFORECAST = 'avalancheforecast'
export const PRODUCT_TYPE_REGIONALDISCUSSION = 'regionaldiscussion'
export const PRODUCT_TYPE_SPECIALPRODUCT = 'specialproduct'
export const PRODUCT_TYPE_HIGHWAYFORECAST = 'highwayforecast'
export const PRODUCT_TYPE_HIGHWAYDISCUSSION = 'highwaydiscussion'
export const PRODUCT_TYPE_MITIGATION = 'mitigation'

export type ProductType =
    | typeof PRODUCT_TYPE_AVALANCHEFORECAST
    | typeof PRODUCT_TYPE_REGIONALDISCUSSION
    | typeof PRODUCT_TYPE_SPECIALPRODUCT
    | typeof PRODUCT_TYPE_HIGHWAYFORECAST
    | typeof PRODUCT_TYPE_HIGHWAYDISCUSSION
    | typeof PRODUCT_TYPE_MITIGATION

export const PRODUCT_STATUS_DRAFT = 'draft'
export const PRODUCT_STATUS_COMPLETED = 'completed'
export const PRODUCT_STATUS_LIVE = 'live'

export type ProductStatus = typeof PRODUCT_STATUS_DRAFT | typeof PRODUCT_STATUS_COMPLETED | typeof PRODUCT_STATUS_LIVE

export const createEmptyProduct = (type: ProductType) => {
    const timezone = process.env.REACT_APP_DEFAULT_TIMEZONE || TIMEZONE_PACIFIC
    const issueENV = JSON.parse(process.env.REACT_APP_PRODUCT_ISSUE_TIME || '[16, 0]')
    const expiryENV = JSON.parse(process.env.REACT_APP_PRODUCT_EXPIRY_TIME || '[16, 0]')

    const now = new Date()
    const today = startOfDay(now)

    const issueDateTime = convertBrowserTimeToServerTime(
        setHours(setMinutes(setSeconds(today, 0), issueENV[1] || 0), issueENV[0] || 16),
        timezone
    )

    const expiryDateTime = convertBrowserTimeToServerTime(
        setHours(setMinutes(setSeconds(today, 0), expiryENV[1] || 0), expiryENV[0] || 16),
        timezone
    )
    expiryDateTime.setDate(expiryDateTime.getDate() + 1)

    return {
        name: null,
        type,
        colour: type === PRODUCT_TYPE_SPECIALPRODUCT ? '#FF0000' : COLOUR_HEXES[0], // red for special products
        status: null,
        userID: null,
        polygons: [],
        username: null,
        media: {
            images: [],
        },
        userHandle: null,
        issueDateTime,
        expiryDateTime,
        dayOne: 'issueDatePlusOne',
        isMultiDay: false,
        timezone,
        isTranslated: false,
        isAutoTranslated: false,
    }
}

export const FILTER_OPTIONS = [
    {
        label: PRODUCT_STATUS_DRAFT,
        value: PRODUCT_STATUS_DRAFT,
        default: true, // default will determine if filter is active on component load
    },
    {
        label: PRODUCT_STATUS_COMPLETED,
        value: PRODUCT_STATUS_COMPLETED,
        default: false,
    },
    {
        label: PRODUCT_STATUS_LIVE,
        value: PRODUCT_STATUS_LIVE,
        default: false,
    },
]

type Product = {
    name: string
    type: ProductType
    colour: string
    status: ProductStatus | null
    userID: string | null
    polygons: string[]
    username: string
    // TODO: Define proper image type
    media: {
        images: any[]
    }
    userHandle: string | null
    issueDateTime: Date
    expiryDateTime: Date
    dayOne: string
    isMultiDay: boolean
    timezone: string
    // TODO: Define other fields
    [key: string]: any
}

export const filterAndSort = (list: Product[], filterType = 'status', filters: ProductStatus[]) => {
    let filteredList: Product[] = []

    for (const f in filters) {
        const filtered = filterSortList(list, filterType, filters[f])
        filteredList = filteredList.concat(filtered)
    }

    return filteredList
}

const filterSortList = (list: Product[], filterType = 'status', filter: ProductStatus) => {
    const newList = list.filter(obj => {
        return obj[filterType] === filter
    })

    const newListWithoutFxers = newList
        .filter(obj => {
            return !obj.username
        })
        .sort((a, b) => {
            return a.name.localeCompare(b.name)
        })

    const newListWithFxers = newList
        .filter(obj => {
            return obj.username
        })
        .sort((a, b) => {
            return a.username === b.username ? a.name.localeCompare(b.name) : a.username.localeCompare(b.username)
        })

    return newListWithFxers.concat(newListWithoutFxers)
}

type PolygonFeature = {
    properties: {
        id: string
    }
}

type Polygons = {
    features: PolygonFeature[]
}

export const unassignedCount = (polygons: Polygons, filteredProducts: Product[], draftProduct: Product) => {
    let unassignedCount = -1
    if (polygons.features && polygons.features.length > 0) {
        const allPolygons = polygons.features.map((feature: PolygonFeature) => feature.properties.id)

        const assignedPolygons = [...filteredProducts, draftProduct]
            .map(product => {
                return product.polygons
            })
            .flat()
        unassignedCount = [...allPolygons].filter(polygon => !assignedPolygons.includes(polygon)).length
    }

    return unassignedCount
}
