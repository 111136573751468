import create from 'zustand'

import { createEmptyProduct } from 'utils/Product'

// type is 'avalancheforecast', 'regionaldiscussion', 'specialproduct', 'highwayforecast', 'highwaydiscussion', or 'mitigation'
export const createStoreForProduct = type => {
    const store = ProductStore(type)

    return {
        useFilters: () => store(state => state.filters),
        useAddFilter: () => store(state => state.addFilter),
        useRemoveFilter: () => store(state => state.removeFilter),
        useActiveProductId: () => store(state => state.activeProductId),
        useSetActiveProductId: () => store(state => state.setActiveProductId),
        useClearActiveProductId: () => store(state => state.clearActiveProductId),
        useEditingProductSetup: () => store(state => state.editingProductSetup),
        useSetEditingProductSetup: () => store(state => state.setEditingProductSetup),
        useEditingProductContent: () => store(state => state.editingProductContent),
        useSetEditingProductContent: () => store(state => state.setEditingProductContent),
        useDraftProduct: () => store(state => state.draftProduct),
        useUpdateDraftProduct: () => store(state => state.updateDraftProduct),
        useResetDraftProduct: () => store(state => state.resetDraftProduct),
    }
}

const ProductStore = type =>
    create(set => ({
        filters: ['draft'],
        editingProductSetup: false,
        editingProductContent: false,
        activeProductId: null,
        draftProduct: createEmptyProduct(type),
        updateDraftProduct: draftProduct => {
            set({ draftProduct })
        },
        resetDraftProduct: () => {
            set({ draftProduct: createEmptyProduct(type) })
        },
        addFilter: filter => {
            set({ filters: [filter] })
        },
        // We aren't removing filters in these products
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        removeFilter: () => {},
        setActiveProductId: id => {
            set({ activeProductId: id })
        },
        clearActiveProductId: () => {
            set({ activeProductId: null, editingProduct: false })
        },
        setEditingProductSetup: editingProductSetup => {
            set({ editingProductSetup })
        },
        setEditingProductContent: editingProductContent => {
            set({ editingProductContent })
        },
    }))
