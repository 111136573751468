import { defineMessages } from 'react-intl'

export default defineMessages({
    undoError: {
        id: 'undoError',
        defaultMessage: 'There was an error reverting the last action',
        description: 'Undo error message',
    },
    nothingToUndo: {
        id: 'nothingToUndo',
        defaultMessage: 'There is nothing to revert',
        description: 'Undo error message',
    },
    revert: {
        id: 'revert',
        defaultMessage: 'Revert',
        description: 'Revert button',
    },
    active: {
        id: 'active',
        defaultMessage: 'Active',
        description: 'Weak layer filters',
    },
    developing: {
        id: 'developing',
        defaultMessage: 'Developing',
        description: 'Weak layers filters',
    },
    dormant: {
        id: 'dormant',
        defaultMessage: 'Dormant',
        description: 'Weak layers filters',
    },
    inactive: {
        id: 'inactive',
        defaultMessage: 'Inactive',
        description: 'Weak layers filters',
    },
    draft: {
        id: 'draft',
        defaultMessage: 'Draft',
        description: 'Avalanche forecasts filters',
    },
    completed: {
        id: 'completed',
        defaultMessage: 'Completed',
        description: 'Avalanche forecasts filters',
    },
    live: {
        id: 'live',
        defaultMessage: 'Live',
        description: 'Avalanche forecasts filters',
    },
    weakLayers: {
        id: 'weakLayers',
        defaultMessage: 'Weak Layers',
        description: 'Main Menu',
    },
    avalancheForecast: {
        id: 'avalancheForecast',
        defaultMessage: 'Avalanche Forecasts',
        description: 'Main Menu',
    },
    avalancheforecast: {
        id: 'avalancheForecast',
        defaultMessage: 'Avalanche Forecasts',
        description: 'Main Menu',
    },
    profile: {
        id: 'profile',
        defaultMessage: 'Profile',
        description: 'Main Menu',
    },
    archive: {
        id: 'archive',
        defaultMessage: 'Archive',
        description: 'Main Menu',
    },
    archiveSearch: {
        id: 'archiveSearch',
        defaultMessage: 'Search for names, problems, etc.',
        description: 'Archive Search',
    },
    search: {
        id: 'search',
        defaultMessage: 'Search',
        description: 'Archive Search',
    },
    productType: {
        id: 'productType',
        defaultMessage: 'Product Type',
        description: 'Archive Search',
    },
    'archive.titles.forecaster': {
        id: 'archive.titles.forecaster',
        defaultMessage: 'Forecaster',
        description: 'Archive table',
    },
    'archive.titles.productType': {
        id: 'archive.titles.productType',
        defaultMessage: 'Type',
        description: 'Archive table',
    },
    'archive.titles.issueDate': {
        id: 'archive.titles.issueDate',
        defaultMessage: 'Issue Date',
        description: 'Archive table',
    },
    'archive.titles.expiryDate': {
        id: 'archive.titles.expiryDate',
        defaultMessage: 'Expiry Date',
        description: 'Archive table',
    },
    'archive.titles.actions': {
        id: 'archive.titles.actions',
        defaultMessage: 'Actions',
        description: 'Archive table',
    },
    dateRange: {
        id: 'dateRange',
        defaultMessage: 'Date Range',
        description: 'Archive Search',
    },
    startDate: {
        id: 'startDate',
        defaultMessage: 'Start Date',
        description: 'Archive Search',
    },
    endDate: {
        id: 'endDate',
        defaultMessage: 'End Date',
        description: 'Archive Search',
    },
    dateRangeError: {
        id: 'dateRangeError',
        defaultMessage: 'The maximum range is {rangeLimit} days. The start date has been adjusted to {startDate}.',
        description: 'Archive Date Range Error',
    },
    language: {
        id: 'language',
        defaultMessage: 'Language',
        description: 'Profile language setting',
    },
    colourScheme: {
        id: 'colourScheme',
        defaultMessage: 'Colour Scheme',
        description: 'Profile language setting',
    },
    notifications: { id: 'notifications', defaultMessage: 'Notifications' },
    dark: { id: 'dark', defaultMessage: 'Dark', description: 'Dark Mode' },
    light: { id: 'light', defaultMessage: 'Light', description: 'Light Mode' },
    allNotifications: {
        id: 'allNotifications',
        defaultMessage: 'All Notifications',
    },
    errorNotifications: {
        id: 'errorNotifications',
        defaultMessage: 'Error Notifications',
    },
    devNotifications: {
        id: 'devNotifications',
        defaultMessage: 'Developer Notifications',
    },
    mode: { id: 'mode', defaultMessage: 'Mode', description: 'Mode heading' },
    dateFormat: {
        id: 'dateFormat',
        defaultMessage: 'Date Format',
        description: 'Date format setting',
    },
    'YYYY-MM-DD': {
        id: 'YYYY-MM-DD',
        defaultMessage: 'Year-Month-Day',
        description: 'Date format setting',
    },
    'DD-MM-YYYY': {
        id: 'DD-MM-YYYY',
        defaultMessage: 'Day-Month-Year',
        description: 'Date format setting',
    },
    'MM-DD-YYYY': {
        id: 'MM-DD-YYYY',
        defaultMessage: 'Month-Day-Year',
        description: 'Date format setting',
    },
    accessibility: {
        id: 'accessibility',
        defaultMessage: 'Accessibility',
        description: 'accessibility profile setting',
    },
    standardAccessibility: {
        id: 'standardAccessibility',
        defaultMessage: 'Standard Accessibility',
        description: 'accessibility profile setting',
    },
    enhancedAccessibility: {
        id: 'enhancedAccessibility',
        defaultMessage: 'Enhanced Accessibility',
        description: 'accessibility profile setting',
    },
    accessibilityTooltip: {
        id: 'accessibilityTooltip',
        defaultMessage:
            'This enables keyboard-navigation and screen-reader-optimized versions of certain components, including tables for the Aspect/Elevation rose and polygon map views.',
        description: 'accessibility profile setting tooltip',
    },
    login: { id: 'login', defaultMessage: 'Login', description: 'Login Button' },
    logout: {
        id: 'logout',
        defaultMessage: 'Logout',
        description: 'Logout Button',
    },
    back: { id: 'back', defaultMessage: 'Back', description: 'Back Button' },
    authMessage: {
        id: 'authMessage',
        defaultMessage: 'You must login to view this page.',
        description: 'Authentication required',
    },
    noWeakLayersFound: {
        id: 'noWeakLayersFound',
        defaultMessage: 'No weak layers found matching this filter.',
        description: 'Weak Layers List',
    },
    createNewWeakLayer: {
        id: 'createNewWeakLayer',
        defaultMessage: 'Create New Weak Layer',
        description: 'Weak Layers List',
    },
    editWeakLayer: {
        id: 'editWeakLayer',
        defaultMessage: 'Edit Weak Layer',
        description: 'Weak Layers List',
    },
    deleteWeakLayer: {
        id: 'deleteWeakLayer',
        defaultMessage: 'Delete Weak Layer',
        description: 'Weak Layers List',
    },
    deleteWeakLayerConfirmation: {
        id: 'deleteWeakLayerConfirmation',
        defaultMessage: 'Are you sure you would like to delete the weak layer “{name}”?',
        description: 'Weak layer deletion',
    },
    warning: {
        id: 'warning',
        defaultMessage: 'Warning!',
        description: 'Weak layer deletion',
    },
    yesConfirm: {
        id: 'yesConfirm',
        defaultMessage: 'Yes, Delete Weak Layer',
        description: 'Weak layer deletion',
    },
    loading: {
        id: 'loading',
        defaultMessage: 'Loading…',
        description: 'Side Bar',
    },
    loadingForecast: {
        id: 'loadingForecastData',
        defaultMessage: 'Loading Forecast Data',
        description: 'loading message',
    },
    delete: {
        id: 'delete',
        defaultMessage: 'Delete',
        description: 'Weak Layers List',
    },
    closeEditing: { id: 'closeEditing', defaultMessage: 'Close Editing' },
    deleteForecast: {
        id: 'deleteForecast',
        defaultMessage: 'Delete Avalanche Forecast',
        description: 'Avalanche Forecasts List',
    },
    editContent: {
        id: 'editContent',
        defaultMessage: 'Edit Content',
        description: 'Avalanche Forecasts List',
    },
    missingForecaster: {
        id: 'missingForecaster',
        defaultMessage: 'A forecaster must be selected in the Setup workflow before editing the content',
        description: 'Product details',
    },
    editSetup: {
        id: 'editSetup',
        defaultMessage: 'Edit Setup',
        description: 'Avalanche Forecasts List',
    },
    reviewButton: {
        id: 'reviewButton',
        defaultMessage: 'Review',
        description: 'Avalanche Forecasts List',
    },
    markAsReviewed: {
        id: 'markAsReviewed',
        defaultMessage: 'Mark as Reviewed',
        description: 'Review panel',
    },
    markAsReviewedError: {
        id: 'markAsReviewedError',
        defaultMessage: 'There was an error marking as reviewed. Please try again later.',
        description: 'Review panel',
    },
    cloneToDraft: {
        id: 'cloneToDraft',
        defaultMessage: 'Clone to Draft',
        description: 'Avalanche Forecasts List',
    },
    clonePolygonError: {
        id: 'cloneErrorPolygons',
        defaultMessage: 'Cloning Error',
        description: 'product cloning error',
    },
    ok: {
        id: 'ok',
        defaultMessage: 'OK',
        description: 'product cloning error',
    },
    productCloneErrorPolygons: {
        id: 'productCloneErrorPolygons',
        defaultMessage:
            'Polygons associated with this product are assigned to a Draft product. To clone this product first unselect the conflicting polygons in Draft.',
        description: 'product cloning error',
    },
    productCloneError: {
        id: 'productCloneError',
        defaultMessage: 'There was an error cloning this product.',
        description: 'product cloning error',
    },
    productCloneNewWindowError: {
        id: 'productCloneNewWindowError',
        defaultMessage: 'There was an error opening the cloned product in a new window.',
        description: 'product cloning error',
    },
    cloneConfirmationTitle: {
        id: 'cloneConfirmationTitle',
        defaultMessage: 'Confirm Clone',
        description: 'product cloning confirmation',
    },
    cloneConfirmationmessage: {
        id: 'cloneConfirmationMessage',
        defaultMessage: 'Are you sure you want to clone "{name}"?',
        description: 'product cloning confirmation',
    },
    cloneConfirm: {
        id: 'cloneConfirm',
        defaultMessage: 'Yes, Clone',
        description: 'product cloning confirmation',
    },
    preview: {
        id: 'preview',
        defaultMessage: 'Preview',
        description: 'Avalanche Forecasts List and more menu option in day card',
    },
    deleteAvalancheForecastConfirmation: {
        id: 'deleteAvalancheForecastConfirmation',
        defaultMessage:
            'Are you sure you want to delete avalanche forecast “{name}”? This action can not be undone. If you are deleting to fix an error, clone before deleting.',
        description: 'Delete confirmation modal',
    },
    yesConfirmDeleteAvalancheForecast: {
        id: 'yesConfirmDeleteAvalancheForecast',
        defaultMessage: 'Yes, Delete Avalanche Forecast',
        description: 'Avalanche forecast deletion',
    },
    edit: { id: 'edit', defaultMessage: 'Edit', description: 'Weak Layers form' },
    close: {
        id: 'close',
        defaultMessage: 'Close',
        description: 'Weak Layers form',
    },
    cancel: {
        id: 'cancel',
        defaultMessage: 'Cancel',
        description: 'Weak Layers form',
    },
    save: {
        id: 'save',
        defaultMessage: 'Save',
        description: 'images form',
    },
    create: {
        id: 'create',
        defaultMessage: 'Create',
        description: 'Weak Layers form',
    },
    precipParticles: {
        id: 'precipParticles',
        defaultMessage: 'Precipitation Particles',
        description: 'Grain Types Enum',
    },
    decomposingFragmented: {
        id: 'decomposingFragmented',
        defaultMessage: 'Decomposing & Fragmented',
        description: 'Grain Types Enum',
    },
    facetedCrystals: {
        id: 'facetedCrystals',
        defaultMessage: 'Faceted Crystals',
        description: 'Grain Types Enum',
    },
    depthHoar: {
        id: 'depthHoar',
        defaultMessage: 'Depth Hoar',
        description: 'Grain Types Enum',
    },
    surfaceHoar: {
        id: 'surfaceHoar',
        defaultMessage: 'Surface Hoar',
        description: 'Grain Types Enum',
    },
    meltFreezeCrust: {
        id: 'meltFreezeCrust',
        defaultMessage: 'Melt-Freeze Crust',
        description: 'Grain Types Enum',
    },
    crustFacet: {
        id: 'crustFacet',
        defaultMessage: 'Crust/Facet Combo',
        description: 'Grain Types Enum',
    },
    crustSurfaceHoar: {
        id: 'crustSurfaceHoar',
        defaultMessage: 'Crust/Surface Hoar Combo',
        description: 'Grain Types Enum',
    },
    surfaceHoarFacet: {
        id: 'surfaceHoarFacet',
        defaultMessage: 'Surface Hoar/Facet Combo',
        description: 'Grain Types Enum',
    },
    shift: { id: 'shift', defaultMessage: 'Shift' },
    instructions: {
        id: 'instructions',
        defaultMessage:
            'Toggle polygons by clicking on them, or use the lasso tool to select a batch (hold {shift} while lassoing to remove).',
        description: 'Polygon selection instructions',
    },
    instructionsEnhancedA11y: {
        id: 'instructionsEnhancedA11y',
        defaultMessage: 'Use the table to toggle individual polygons of the current active product.',
        description: 'Polygon selection instructions for enhanced accessibility',
    },
    by: { id: 'by', defaultMessage: 'by', description: 'Comment header' },
    selectWeakLayer: {
        id: 'selectWeakLayer',
        defaultMessage: 'Select Weak Layer',
        description: 'weak layer selector placeholder',
    },
    submitComment: {
        id: 'submitComment',
        defaultMessage: 'Submit Comment',
        description: 'Comment form',
    },
    submitProblem: {
        id: 'submitProblem',
        defaultMessage: 'Submit Problem',
        description: 'Problem form',
    },
    updateProblem: {
        id: 'updateProblem',
        defaultMessage: 'Update Problem',
        description: 'Problem form',
    },
    saveProblemCharacterCountError: {
        id: 'saveProblemCharacterCountError',
        defaultMessage: 'The problem discussion has too many characters. Please shorten the text.',
        description: 'Problem form',
    },
    lastModified: {
        id: 'lastModified',
        defaultMessage: 'Last modified',
        description: 'Weak Layer metadata',
    },
    lastSaved: { id: 'lastSaved', defaultMessage: 'Last Saved' },
    grainType: {
        id: 'grainType',
        defaultMessage: 'Grain Type',
        description: 'Weak Layer metadata',
    },
    depth: {
        id: 'depth',
        defaultMessage: 'Depth',
        description: 'Weak Layer metadata',
    },
    thickness: {
        id: 'thickness',
        defaultMessage: 'Thickness',
        description: 'Weak Layer metadata',
    },
    hazardChart: {
        id: 'hazardChart',
        defaultMessage: 'Hazard Chart',
        description: 'Hazard chart metadata',
    },
    centroid: {
        id: 'centroid',
        defaultMessage: 'Centroid',
        description: 'Accessible Hazard Chart labels',
    },
    sizeRange: {
        id: 'sizeRange',
        defaultMessage: 'Size Range',
        description: 'Accessible Hazard Chart labels',
    },
    likelihoodRange: {
        id: 'likelihoodRange',
        defaultMessage: 'Likelihood Range',
        description: 'Accessible Hazard Chart labels',
    },
    centroidFromForm: {
        id: 'centroidFromForm',
        defaultMessage: 'Centroid (from form)',
        description: 'Accessible Hazard Chart labels',
    },
    size: {
        id: 'size',
        defaultMessage: 'Size',
        description: 'Accessible Hazard Chart labels',
    },
    likelihood: {
        id: 'likelihood',
        defaultMessage: 'Likelihood',
        description: 'Accessible Hazard Chart labels',
    },
    centroidSize: {
        id: 'centroidSize',
        defaultMessage: 'Centroid Size',
        description: 'Accessible Hazard Chart labels',
    },
    centroidLikelihood: {
        id: 'centroidLikelihood',
        defaultMessage: 'Centroid Likelihood',
        description: 'Accessible Hazard Chart labels',
    },
    sizeRangeFrom: {
        id: 'sizeRangeFrom',
        defaultMessage: 'Size Range From',
        description: 'Accessible Hazard Chart labels',
    },
    sizeRangeTo: {
        id: 'sizeRangeTo',
        defaultMessage: 'Size Range To',
        description: 'Accessible Hazard Chart labels',
    },
    likelihoodRangeFrom: {
        id: 'likelihoodRangeFrom',
        defaultMessage: 'Likelihood Range From',
        description: 'Accessible Hazard Chart labels',
    },
    likelihoodRangeTo: {
        id: 'likelihoodRangeTo',
        defaultMessage: 'Likelihood Range To',
        description: 'Accessible Hazard Chart labels',
    },
    formDerivedSize: {
        id: 'formDerivedSize',
        defaultMessage: 'Form Derived Size',
        description: 'Accessible Hazard Chart labels',
    },
    formDerivedLikelihood: {
        id: 'formDerivedLikelihood',
        defaultMessage: 'Form Derived Likelihood',
        description: 'Accessible Hazard Chart labels',
    },
    aspectElevation: {
        id: 'aspectElevation',
        defaultMessage: 'Aspect/Elevation',
        description: 'Weak Layer metadata',
    },
    useTableforAERose: {
        id: 'useTableforAERose',
        defaultMessage: 'Use table for Aspect/Elevations:',
        description: 'Switch for aspect elevation rose to table',
    },
    selected: {
        id: 'selected',
        defaultMessage: 'Selected',
        description: 'Weak Layer table column header',
    },
    unselected: {
        id: 'unselected',
        defaultMessage: 'Unselected',
        description: 'Weak Layer table column header',
    },
    selectedAspects: {
        id: 'selectedAspects',
        defaultMessage: 'Selected Aspects',
        description: 'Weak Layer list header',
    },
    accessibleAspectList: {
        id: 'accessibleAspectList',
        defaultMessage: 'Aspect Elevation Chart. Selected aspects: {selectedAspects}.',
        description: 'list of selected aspects for accessbility',
    },
    selectAll: {
        id: 'selectAll',
        defaultMessage: 'Select All',
        description: 'Weak Layer table column header',
    },
    selectAllAspects: {
        id: 'selectAllAspects',
        defaultMessage: 'Select all aspects and elevations',
        description: 'Select all checkbox label for selecting aspects and elevations',
    },
    unselectAllAspects: {
        id: 'unselectAllAspects',
        defaultMessage: 'Unselect all aspects and elevations',
        description: 'Select all checkbox label for unselecting aspects and elevations',
    },
    N: {
        id: 'N',
        defaultMessage: 'N',
        description: 'Compass rose labels',
    },
    S: {
        id: 'S',
        defaultMessage: 'S',
        description: 'Compass rose labels',
    },
    W: {
        id: 'W',
        defaultMessage: 'W',
        description: 'Compass rose labels',
    },
    E: {
        id: 'E',
        defaultMessage: 'E',
        description: 'Compass rose labels',
    },
    n_alp: {
        id: 'n_alp',
        defaultMessage: 'North Alpine',
        description: 'Compass rose labels',
    },
    n_tln: {
        id: 'n_tln',
        defaultMessage: 'North Treeline',
        description: 'Compass rose labels',
    },
    n_btl: {
        id: 'n_btl',
        defaultMessage: 'North Below Treeline',
        description: 'Compass rose labels',
    },
    nw_alp: {
        id: 'nw_alp',
        defaultMessage: 'Northwest Alpine',
        description: 'Compass rose labels',
    },
    nw_tln: {
        id: 'nw_tln',
        defaultMessage: 'Northwest Treeline',
        description: 'Compass rose labels',
    },
    nw_btl: {
        id: 'nw_btl',
        defaultMessage: 'Northwest Below Treeline',
        description: 'Compass rose labels',
    },
    w_alp: {
        id: 'w_alp',
        defaultMessage: 'West Alpine',
        description: 'Compass rose labels',
    },
    w_tln: {
        id: 'w_tln',
        defaultMessage: 'West Treeline',
        description: 'Compass rose labels',
    },
    w_btl: {
        id: 'w_btl',
        defaultMessage: 'West Below Treeline',
        description: 'Compass rose labels',
    },
    sw_alp: {
        id: 'sw_alp',
        defaultMessage: 'Southwest Alpine',
        description: 'Compass rose labels',
    },
    sw_tln: {
        id: 'sw_tln',
        defaultMessage: 'Southwest Treeline',
        description: 'Compass rose labels',
    },
    sw_btl: {
        id: 'sw_btl',
        defaultMessage: 'Southwest Below Treeline',
        description: 'Compass rose labels',
    },
    s_alp: {
        id: 's_alp',
        defaultMessage: 'South Alpine',
        description: 'Compass rose labels',
    },
    s_tln: {
        id: 's_tln',
        defaultMessage: 'South Treeline',
        description: 'Compass rose labels',
    },
    s_btl: {
        id: 's_btl',
        defaultMessage: 'South Below Treeline',
        description: 'Compass rose labels',
    },
    se_alp: {
        id: 'se_alp',
        defaultMessage: 'Southeast Alpine',
        description: 'Compass rose labels',
    },
    se_tln: {
        id: 'se_tln',
        defaultMessage: 'Southeast Treeline',
        description: 'Compass rose labels',
    },
    se_btl: {
        id: 'se_btl',
        defaultMessage: 'Southeast Below Treeline',
        description: 'Compass rose labels',
    },
    e_alp: {
        id: 'e_alp',
        defaultMessage: 'East Alpine',
        description: 'Compass rose labels',
    },
    e_tln: {
        id: 'e_tln',
        defaultMessage: 'East Treeline',
        description: 'Compass rose labels',
    },
    e_btl: {
        id: 'e_btl',
        defaultMessage: 'East Below Treeline',
        description: 'Compass rose labels',
    },
    ne_alp: {
        id: 'ne_alp',
        defaultMessage: 'Northeast Alpine',
        description: 'Compass rose labels',
    },
    ne_tln: {
        id: 'ne_tln',
        defaultMessage: 'Northeast Treeline',
        description: 'Compass rose labels',
    },
    ne_btl: {
        id: 'ne_btl',
        defaultMessage: 'Northeast Below Treeline',
        description: 'Compass rose labels',
    },
    n_alp_compact: {
        id: 'n_alp_compact',
        defaultMessage: 'N Alpine',
        description: 'Compass rose labels',
    },
    n_tln_compact: {
        id: 'n_tln_compact',
        defaultMessage: 'N Treeline',
        description: 'Compass rose labels',
    },
    n_btl_compact: {
        id: 'n_btl_compact',
        defaultMessage: 'N Below Treeline',
        description: 'Compass rose labels',
    },
    nw_alp_compact: {
        id: 'nw_alp_compact',
        defaultMessage: 'NW Alpine',
        description: 'Compass rose labels',
    },
    nw_tln_compact: {
        id: 'nw_tln_compact',
        defaultMessage: 'NW Treeline',
        description: 'Compass rose labels',
    },
    nw_btl_compact: {
        id: 'nw_btl_compact',
        defaultMessage: 'NW Below Treeline',
        description: 'Compass rose labels',
    },
    w_alp_compact: {
        id: 'w_alp_compact',
        defaultMessage: 'W Alpine',
        description: 'Compass rose labels',
    },
    w_tln_compact: {
        id: 'w_tln_compact',
        defaultMessage: 'W Treeline',
        description: 'Compass rose labels',
    },
    w_btl_compact: {
        id: 'w_btl_compact',
        defaultMessage: 'W Below Treeline',
        description: 'Compass rose labels',
    },
    sw_alp_compact: {
        id: 'sw_alp_compact',
        defaultMessage: 'SW Alpine',
        description: 'Compass rose labels',
    },
    sw_tln_compact: {
        id: 'sw_tln_compact',
        defaultMessage: 'SW Treeline',
        description: 'Compass rose labels',
    },
    sw_btl_compact: {
        id: 'sw_btl_compact',
        defaultMessage: 'SW Below Treeline',
        description: 'Compass rose labels',
    },
    s_alp_compact: {
        id: 's_alp_compact',
        defaultMessage: 'S Alpine',
        description: 'Compass rose labels',
    },
    s_tln_compact: {
        id: 's_tln_compact',
        defaultMessage: 'S Treeline',
        description: 'Compass rose labels',
    },
    s_btl_compact: {
        id: 's_btl_compact',
        defaultMessage: 'S Below Treeline',
        description: 'Compass rose labels',
    },
    se_alp_compact: {
        id: 'se_alp_compact',
        defaultMessage: 'SE Alpine',
        description: 'Compass rose labels',
    },
    se_tln_compact: {
        id: 'se_tln_compact',
        defaultMessage: 'SE Treeline',
        description: 'Compass rose labels',
    },
    se_btl_compact: {
        id: 'se_btl_compact',
        defaultMessage: 'SE Below Treeline',
        description: 'Compass rose labels',
    },
    e_alp_compact: {
        id: 'e_alp_compact',
        defaultMessage: 'E Alpine',
        description: 'Compass rose labels',
    },
    e_tln_compact: {
        id: 'e_tln_compact',
        defaultMessage: 'E Treeline',
        description: 'Compass rose labels',
    },
    e_btl_compact: {
        id: 'e_btl_compact',
        defaultMessage: 'E Below Treeline',
        description: 'Compass rose labels',
    },
    ne_alp_compact: {
        id: 'ne_alp_compact',
        defaultMessage: 'NE Alpine',
        description: 'Compass rose labels',
    },
    ne_tln_compact: {
        id: 'ne_tln_compact',
        defaultMessage: 'NE Treeline',
        description: 'Compass rose labels',
    },
    ne_btl_compact: {
        id: 'ne_btl_compact',
        defaultMessage: 'NE Below Treeline',
        description: 'Compass rose labels',
    },
    latestComment: {
        id: 'latestComment',
        defaultMessage: 'Latest Comment',
        description: 'Weak Layer metadata',
    },
    polygons: {
        id: 'polygons',
        defaultMessage: '{value, plural, =1 {# Polygon} other {# Polygons}}',
        description: 'Weak Layer metadata',
    },
    noPolygonDetails: {
        id: 'noPolygonDetails',
        defaultMessage: 'Polygon details not available',
        description: 'products metadata',
    },
    to: { id: 'to', defaultMessage: 'to', description: 'Weak Layer metadata' },
    buried: {
        id: 'buried',
        defaultMessage: 'Buried',
        description: 'Weak Layers metadata',
    },
    status: {
        id: 'status',
        defaultMessage: 'Status',
        description: 'Weak Layers metadata',
    },
    checkAll: {
        id: 'checkAll',
        defaultMessage: 'Check All',
        description: 'Check All button av forecasts content',
    },
    details: {
        id: 'details',
        defaultMessage: 'Details',
        description: 'Weak Layers metadata',
    },
    comments: {
        id: 'comments',
        defaultMessage: 'Comments',
        description: 'weak Layers metadata',
    },
    deletedWeakLayer: {
        id: 'deletedWeakLayer',
        defaultMessage: 'Deleted Weak Layer',
        description: 'Weak Layers metadata',
    },
    weakLayerName: {
        id: 'weakLayerName',
        defaultMessage: 'Weak Layer Name',
        description: 'Weak Layer form',
    },
    nameRequired: {
        id: 'nameRequired',
        defaultMessage: 'Please input a name',
        description: 'Product form',
    },
    nameTooShort: {
        id: 'nameTooShort',
        defaultMessage: 'Name must be minimum 3 characters',
        description: 'Product form',
    },
    nameTooLong: {
        id: 'nameTooLong',
        defaultMessage: 'Name must be maximum 100 characters',
        description: 'Product form',
    },
    burialDate: {
        id: 'burialDate',
        defaultMessage: 'Burial Date',
        description: 'Weak Layer form',
    },
    burialDateRequiredMessage: {
        id: 'burialDateRequiredMessage',
        defaultMessage: 'Please input a burial date',
        description: 'Weak Layer form',
    },
    statusRequiredMessage: {
        id: 'statusRequiredMessage',
        defaultMessage: 'Please choose a status for this weak layer',
        description: 'Weak Layer form',
    },
    grainTypeRequiredMessage: {
        id: 'grainTypeRequiredMessage',
        defaultMessage: 'Please choose a grain type for this weak layer',
        description: 'Weak Layer form',
    },
    depthFrom: {
        id: 'depthFrom',
        defaultMessage: 'Depth from (cm)',
        description: 'Weak Layer form',
    },
    depthTo: {
        id: 'depthTo',
        defaultMessage: 'Depth to (cm)',
        description: 'Weak Layer form',
    },
    depthRequiredMessage: {
        id: 'depthRequiredMessage',
        defaultMessage: 'Please input a depth for this weak layer',
        description: 'Weak Layer form',
    },
    toFromError: {
        id: 'toFromError',
        defaultMessage: 'The from value must be less than the to value',
        description: 'Weak Layer form',
    },
    thicknessFrom: {
        id: 'thicknessFrom',
        defaultMessage: 'Thickness from (mm)',
        description: 'Weak Layer form',
    },
    thicknessTo: {
        id: 'thicknessTo',
        defaultMessage: 'Thickness to (mm)',
        description: 'Weak Layer form',
    },
    thicknessRequiredMessage: {
        id: 'thicknessRequiredMessage',
        defaultMessage: 'Please input a thickness for this weak layer',
        description: 'Weak Layer form',
    },
    noAvalancheForecastsFound: {
        id: 'noAvalancheForecastsFound',
        defaultMessage: 'No avalanche forecasts found matching this filter.',
        description: 'Avalanche Forecast list',
    },
    forecastValidationNameRequired: {
        id: 'forecastValidationNameRequired',
        defaultMessage: 'Please input a name for this avalanche forecast',
        description: 'create & edit form',
    },
    forecastValidationDate: {
        id: 'forecastValidationDate',
        defaultMessage: 'Please input issued and expiry dates and times',
        description: 'create & edit form',
    },
    forecaster: {
        id: 'forecaster',
        defaultMessage: 'Forecaster',
        description: 'Product create and edit form',
    },
    productName: {
        id: 'productName',
        defaultMessage: 'Product Name',
        description: 'Product create and edit form',
    },
    forecastName: {
        id: 'forecastName',
        defaultMessage: 'Forecast Name',
        description: 'Product create and edit form',
    },
    dateSelectionError: {
        id: 'dateSelectionError',
        defaultMessage: 'Click "OK" to save date selection',
        description: 'Error for date selector',
    },
    invalidDateRange: {
        id: 'invalidDateRange',
        defaultMessage: 'The expiry date must be after the issued date',
        description: 'Error for date selector',
    },
    issueDateTime: {
        id: 'issueDateTime',
        defaultMessage: 'Issued Date/Time',
        description: 'Product create and edit form',
    },
    expiryDateTime: {
        id: 'expiryDateTime',
        defaultMessage: 'Expiry Date/Time',
        description: 'Product create and edit form',
    },
    startDateTime: {
        id: 'startDateTime',
        defaultMessage: 'Start Date/Time',
        description: 'Product create and edit form',
    },
    issueAndExpiry: {
        id: 'issueAndExpiry',
        defaultMessage: 'Issued & Expiry Date/Time',
        description: 'Product create and edit form',
    },
    coral: {
        id: 'coral',
        defaultMessage: 'Coral',
        description: 'Product Colour Options',
    },
    cyan: {
        id: 'cyan',
        defaultMessage: 'Cyan',
        description: 'Product Colour Options',
    },
    aquamarine: {
        id: 'aquamarine',
        defaultMessage: 'Aquamarine',
        description: 'Product Colour Options',
    },
    burlywood: {
        id: 'burlywood',
        defaultMessage: 'Burlywood',
        description: 'Product Colour Options',
    },
    darkGoldenrod: {
        id: 'darkGoldenrod',
        defaultMessage: 'Dark Goldenrod',
        description: 'Product Colour Options',
    },
    darkBlue: {
        id: 'darkBlue',
        defaultMessage: 'Dark Blue',
        description: 'Product Colour Options',
    },
    darkCyan: {
        id: 'darkCyan',
        defaultMessage: 'Dark Cyan',
        description: 'Product Colour Options',
    },
    darkMagenta: {
        id: 'darkMagenta',
        defaultMessage: 'Dark Magenta',
        description: 'Product Colour Options',
    },
    darkKhaki: {
        id: 'darkKhaki',
        defaultMessage: 'Dark Khaki',
        description: 'Product Colour Options',
    },
    deepPink: {
        id: 'deepPink',
        defaultMessage: 'Deep Pink',
        description: 'Product Colour Options',
    },
    indianRed: {
        id: 'indianRed',
        defaultMessage: 'Indian Red',
        description: 'Product Colour Options',
    },
    lightSeaGreen: {
        id: 'lightSeaGreen',
        defaultMessage: 'Light Sea Green',
        description: 'Product Colour Options',
    },
    orchid: {
        id: 'orchid',
        defaultMessage: 'Orchid',
        description: 'Product Colour Options',
    },
    yellowGreen: {
        id: 'yellowGreen',
        defaultMessage: 'Yellow Green',
        description: 'Product Colour Options',
    },
    tan: {
        id: 'tan',
        defaultMessage: 'Tan',
        description: 'Product Colour Options',
    },
    violet: {
        id: 'violet',
        defaultMessage: 'Violet',
        description: 'Product Colour Options',
    },
    sienna: {
        id: 'sienna',
        defaultMessage: 'Sienna',
        description: 'Product Colour Options',
    },
    createAvalancheForecast: {
        id: 'createAvalancheForecast',
        defaultMessage: 'Set Up New Forecast',
        description: 'Avalanche Forecast list',
    },
    editAvalancheForecast: {
        id: 'editAvalancheForecast',
        defaultMessage: 'Edit Forecast Setup',
        description: 'Avalanche Forecast list',
    },
    issued: {
        id: 'issued',
        defaultMessage: 'Issued',
        description: 'Avalanche Forecast list',
    },
    cloneAllButton: {
        id: 'cloneAllButton',
        defaultMessage: 'Delete Drafts and Start New Day',
        description: 'Avalanche Forecast list',
    },
    cloneAllWarning: {
        id: 'cloneAllWarning',
        defaultMessage: 'This action will delete all existing drafts. Are you sure you want to do this?',
    },
    cloneAllYes: {
        id: 'cloneAllYes',
        defaultMessage: 'Yes, Clone All',
        description: 'Confirmation modal',
    },
    initializeAllYes: {
        id: 'initializeAllYes',
        defaultMessage: 'Yes, Initialize All',
        description: 'Confirmation modal',
    },
    cloneAllSuccess: {
        id: 'cloneAllSuccess',
        defaultMessage: 'Cloning successful',
    },
    colour: {
        id: 'colour',
        defaultMessage: 'Colour',
        description: 'Avalanche Forecast list',
    },
    forecastDayOne: {
        id: 'forecastDayOne',
        defaultMessage: 'Forecast Day 1',
        description: 'Avalanche Forecast details',
    },
    expiryAbbreviation: {
        id: 'expiryAbbreviation',
        defaultMessage: 'Exp: {date}',
        description: 'Inside the map',
    },
    expiry: {
        id: 'expiry',
        defaultMessage: 'Expires ',
        description: 'Map Status Bar',
    },
    issuedStatusBar: {
        id: 'issuedStatusBar',
        defaultMessage: 'Issued ',
        description: 'Map Status Bar',
    },
    originalExpiryDateTime: {
        id: 'originalExpiryDateTime',
        defaultMessage: 'Original Expiry Date/Time',
        description: 'Product create and edit form',
    },
    weatherSummary: {
        id: 'weatherSummary',
        defaultMessage: 'Weather Summary',
        description: 'Forecast content menu',
    },
    snowpackSummary: {
        id: 'snowpackSummary',
        defaultMessage: 'Snowpack Summary',
        description: 'Forecast content menu',
    },
    avalancheSummary: {
        id: 'avalancheSummary',
        defaultMessage: 'Avalanche Summary',
    },
    dangerRatings: {
        id: 'dangerRatings',
        defaultMessage: 'Danger Ratings',
    },
    highwayHazard: {
        id: 'highwayHazard',
        defaultMessage: 'Highway Hazard',
    },
    avalancheProblems: {
        id: 'avalancheProblems',
        defaultMessage: 'Avalanche Problems',
    },
    highwayForecastAvalancheProblems: {
        id: 'highwayForecastAvalancheProblems',
        defaultMessage: 'Avalanche Problems',
    },
    avalancheProblem: {
        id: 'avalancheProblem',
        defaultMessage: 'Avalanche Problem',
    },
    confidence: { id: 'confidence', defaultMessage: 'Confidence' },
    media: {
        id: 'media',
        defaultMessage: 'Media',
        description: 'Content workflow',
    },
    communications: {
        id: 'communications',
        defaultMessage: 'Communications',
        description: 'Content workflow',
    },
    message: {
        id: 'message',
        defaultMessage: 'Message',
        description: 'Content workflow',
    },
    setup: { id: 'setup', defaultMessage: 'Setup' },
    review: { id: 'review', defaultMessage: 'Review' },
    apiErrorPrefix: {
        id: 'apiErrorPrefix',
        defaultMessage: 'API Error: {error}',
        description: 'Notification popup',
    },
    weakLayerSaved: {
        id: 'weakLayerSaved',
        defaultMessage: 'Weak layer “{name}” saved',
        description: 'Notification popup',
    },
    weakLayerDeleted: {
        id: 'weakLayerDeleted',
        defaultMessage: 'Weak layer “{name}” deleted',
        description: 'Notification popup',
    },
    avalancheForecastSaved: {
        id: 'avalancheForecastSaved',
        defaultMessage: 'Avalanche forecast “{name}” saved',
        description: 'Notification popup',
    },
    avalancheForecastSaveError: {
        id: 'avalancheForecastSaveError',
        defaultMessage: 'Avalanche forecast “{name}” could not be saved',
        description: 'Notification popup',
    },
    regionalDiscussionSaveError: {
        id: 'regionalDiscussionSaveError',
        defaultMessage: 'Regional discussion “{name}” could not be saved',
        description: 'Notification popup',
    },
    specialProductSaveError: {
        id: 'specialProductSaveError',
        defaultMessage: 'Special product “{name}” could not be saved',
        description: 'Notification popup',
    },
    version: {
        id: 'version',
        defaultMessage: 'Version',
        description: 'Details pane',
    },
    avalancheForecastDeleted: {
        id: 'avalancheForecastDeleted',
        defaultMessage: 'Avalanche forecast “{name}” deleted',
        description: 'Notification popup',
    },
    unassignedPolygons: {
        id: 'unassignedPolygons',
        defaultMessage: 'Unassigned polygons: {count}',
        description: 'Map view',
    },
    dayOne: {
        id: 'dayOne',
        defaultMessage: 'Day One',
        description: 'Avalanche Forecast details',
    },
    translated: {
        id: 'Translated',
        defaultMessage: 'Translated',
        description: 'Avalanche Forecast details',
    },
    issueDate: {
        id: 'issueDate',
        defaultMessage: 'Issue Date',
        description: 'Avalanche Forecast details',
    },
    issueDatePlusOne: {
        id: 'issueDatePlusOne',
        defaultMessage: 'Issue Date + 1',
        description: 'Avalanche Forecast details',
    },
    public: {
        id: 'public',
        defaultMessage: 'public',
        description: 'Avalanche problem form',
    },
    publish: {
        id: 'publish',
        defaultMessage: 'Publish',
        description: 'Avalanche Forecast details',
    },
    disablePublishAlert: {
        id: 'disablePublishAlert',
        defaultMessage:
            'One or more character limits are exceeded. Go to the content section and reduce the length of the text to publish this forecast.',
        description: 'Avalanche Forecast details',
    },
    regionalDiscussions: {
        id: 'regionalDiscussions',
        defaultMessage: 'Regional Discussions',
        description: 'Regional Discussions',
    },
    regionaldiscussion: {
        id: 'regionalDiscussions',
        defaultMessage: 'Regional Discussions',
        description: 'Regional Discussions',
    },
    noRegionalDiscussionsFound: {
        id: 'noRegionalDiscussionsFound',
        defaultMessage: 'No regional discussions found matching this filter.',
        description: 'Regional Discussions list',
    },
    createRegionalDiscussion: {
        id: 'createRegionalDiscussion',
        defaultMessage: 'Set Up New Regional Discussion',
        description: 'Regional Discussions list',
    },
    deleteRegionalDiscussion: {
        id: 'deleteRegionalDiscussion',
        defaultMessage: 'Delete Regional Discussion',
        description: 'Regional Discussion List',
    },
    deleteRegionalDiscussionConfirmation: {
        id: 'deleteRegionalDiscussionConfirmation',
        defaultMessage:
            'Are you sure you want to delete regional discussion “{name}”? This action can not be undone. If you are deleting to fix an error, clone before deleting.',
        description: 'Delete confirmation modal',
    },
    yesConfirmDeleteRegionalDiscussion: {
        id: 'yesConfirmDeleteRegionalDiscussion',
        defaultMessage: 'Yes, Delete Regional Discussion',
        description: 'Regional discussion deletion',
    },
    regionalDiscussionName: {
        id: 'regionalDiscussionName',
        defaultMessage: 'Regional Discussion Name',
        description: 'Regional Discussion form',
    },
    editRegionalDiscussion: {
        id: 'editRegionalDiscussion',
        defaultMessage: 'Edit Regional Discussion Setup',
        description: 'Regional Discussion form',
    },
    regionalDiscussionSaved: {
        id: 'regionalDiscussionSaved',
        defaultMessage: 'Regional discussion “{name}” saved',
        description: 'Notification popup',
    },
    regionalDiscussionDeleted: {
        id: 'regionalDiscussionDeleted',
        defaultMessage: 'Regional discussion “{name}” deleted',
        description: 'Notification popup',
    },
    specialProducts: {
        id: 'specialProducts',
        defaultMessage: 'Special Products',
        description: 'Special Products',
    },
    specialproduct: {
        id: 'specialProducts',
        defaultMessage: 'Special Products',
        description: 'Special Products',
    },
    noSpecialProductsFound: {
        id: 'noSpecialProductsFound',
        defaultMessage: 'No special products found matching this filter.',
        description: 'Special Products list',
    },
    createSpecialProduct: {
        id: 'createSpecialProduct',
        defaultMessage: 'Set Up New Special Product',
        description: 'Special Products list',
    },
    deleteSpecialProduct: {
        id: 'deleteSpecialProduct',
        defaultMessage: 'Delete Special Product',
        description: 'Special Product List',
    },
    deleteSpecialProductConfirmation: {
        id: 'deleteSpecialProductConfirmation',
        defaultMessage:
            'Are you sure you want to delete special product “{name}”? This action can not be undone. If you are deleting to fix an error, clone before deleting.',
        description: 'Delete confirmation modal',
    },
    yesConfirmDeleteSpecialProduct: {
        id: 'yesConfirmDeleteSpecialProduct',
        defaultMessage: 'Yes, Delete Special Product',
        description: 'Special product deletion',
    },
    specialProductName: {
        id: 'specialProductName',
        defaultMessage: 'Special Product Name',
        description: 'Special Product form',
    },
    specialProductType: {
        id: 'specialProductType',
        defaultMessage: 'Special Product Type',
        description: 'Special Product form',
    },
    specialProductTypeWarning: {
        id: 'specialProductTypeWarning',
        defaultMessage: 'Warning',
    },
    specialProductTypeWatch: {
        id: 'specialProductTypeWatch',
        defaultMessage: 'Watch',
    },
    specialProductTypeSpecialAdvisory: {
        id: 'specialProductTypeSpecialAdvisory',
        defaultMessage: 'Special Avalanche Advisory',
    },
    editSpecialProduct: {
        id: 'editSpecialProduct',
        defaultMessage: 'Edit Special Product Setup',
        description: 'Special Product form',
    },
    specialProductSaved: {
        id: 'specialProductSaved',
        defaultMessage: 'Special product “{name}” saved',
        description: 'Notification popup',
    },
    specialProductDeleted: {
        id: 'specialProductDeleted',
        defaultMessage: 'Special product “{name}” deleted',
        description: 'Notification popup',
    },
    editProductContent: {
        id: 'editProductContent',
        defaultMessage: 'Edit “{product}” Content',
        description: 'Special Product form',
    },
    previewProductContent: {
        id: 'previewProductContent',
        defaultMessage: '“{product}” Preview',
        description: 'Header for product preview',
    },
    nowcastLabel: { id: 'nowcastLabel', defaultMessage: 'Nowcast' },
    forecastLabel: { id: 'forecastLabel', defaultMessage: 'Forecast' },
    english: {
        id: 'english',
        defaultMessage: 'English',
        description: 'Avalanche Forecast details weather forecast',
    },
    french: {
        id: 'french',
        defaultMessage: 'French',
        description: 'Avalanche Forecast details weather forecast',
    },
    en: { id: 'en', defaultMessage: 'English' },
    fr: { id: 'fr', defaultMessage: 'French' },
    addLanguage: { id: 'addLanguage', defaultMessage: 'Add Language' },
    expandedEditor: { id: 'expandedEditor', defaultMessage: 'Expanded Editor' },
    daysAgo: {
        id: 'daysAgo',
        defaultMessage: '{value, plural, =1 {# Day} other {# Days}} ago',
    },
    hoursAgo: {
        id: 'hoursAgo',
        defaultMessage: '{value, plural, =1 {# Hour} other {# Hours}} ago',
    },
    minutesAgo: {
        id: 'minutesAgo',
        defaultMessage: '{value, plural, =1 {# Minute} other {# Minutes}} ago',
    },
    lessThanMinute: {
        id: 'lessThanMinute',
        defaultMessage: 'Less than a minute ago',
    },
    visibleCards: {
        id: 'visibleCards',
        defaultMessage: 'Visible Cards:',
        description: 'Day Selector',
    },
    tag: { id: 'tag', defaultMessage: 'Tag', description: 'Media Images' },
    tagSection: {
        id: 'tagSection',
        defaultMessage: 'Forecast Section',
        description: 'Media Images',
    },
    weatherTag: {
        id: 'weatherTag',
        defaultMessage: 'Weather',
        description: 'Media Images',
    },
    avalancheTag: {
        id: 'avalancheTag',
        defaultMessage: 'Avalanche',
        description: 'Media Images',
    },
    snowpackTag: {
        id: 'snowpackTag',
        defaultMessage: 'Snowpack',
        description: 'Media Images',
    },
    incidentTag: {
        id: 'incidentTag',
        defaultMessage: 'Incident',
        description: 'Media Images',
    },
    generalTag: {
        id: 'generalTag',
        defaultMessage: 'General',
        description: 'Media Images',
    },
    dateTaken: {
        id: 'dateTaken',
        defaultMessage: 'Date Taken',
        description: 'Media Images',
    },
    dateTakenError: {
        id: 'dateTakenError',
        defaultMessage: 'Date taken can not be left blank',
        description: 'Media Images',
    },
    archivedPhoto: {
        id: 'archivedPhoto',
        defaultMessage: 'Archival Photo?',
        description: 'Media Images',
    },
    altText: {
        id: 'altText',
        defaultMessage: 'Alt Text',
        description: 'Media Images',
    },
    altTextFieldHelperText: {
        id: 'altTextFieldHelperText',
        defaultMessage:
            'Describe the image for someone who can\'t see it. "An avalanche crown on a steep mountain slope"',
        description: 'Media Images',
    },
    captionTextHelperText: {
        id: 'captionTextHelperText',
        defaultMessage: 'Provide additional context or information about the image.',
        description: 'Media Images',
    },
    cloudinaryUrl: {
        id: 'cloudinaryUrl',
        defaultMessage: 'Copy and paste a Cloudinary URL or upload an image above',
        description: 'Image form',
    },
    cloudinaryUrlLabel: {
        id: 'cloudinaryUrlLabel',
        defaultMessage: 'Cloudinary URL',
        description: 'Image form',
    },
    addImage: {
        id: 'addImage',
        defaultMessage: 'Add Image',
        description: 'Image form',
    },
    deleteImageConfirmation: {
        id: 'deleteImageConfirmation',
        defaultMessage: 'Are you sure you want to delete the selected image? This action can not be undone.',
        description: 'Media section delete modal',
    },
    deleteImageConfirmationButton: {
        id: 'deleteImageConfirmationButton',
        defaultMessage: 'Yes, Delete Image',
        description: 'Media section delete modal',
    },
    upload: { id: 'upload', defaultMessage: 'Upload', description: 'Image form' },
    credit: { id: 'credit', defaultMessage: 'Credit', description: 'Image form' },
    caption: {
        id: 'caption',
        defaultMessage: 'Caption',
        description: 'Image form',
    },
    captionEn: {
        id: 'captionEn',
        defaultMessage: 'Caption (English)',
        description: 'Image form',
    },
    captionFr: {
        id: 'captionFr',
        defaultMessage: 'Caption (French)',
        description: 'Image form',
    },
    archival: {
        id: 'archival',
        defaultMessage: 'Archival',
        description: 'Image form',
    },
    daysOld: {
        id: 'daysOld',
        defaultMessage: '{value, plural, =1 {# Day} other {# Days}} old',
        description: 'Image form',
    },
    incomplete: {
        id: 'incomplete',
        defaultMessage: 'Incomplete',
        description: 'Image form',
    },
    invalidImageFile: {
        id: 'invalidImageFile',
        defaultMessage: 'Invalid image file',
        description: 'Image form',
    },
    changesAreAutosaved: {
        id: 'changesAreAutosaved',
        defaultMessage: 'Changes are autosaved',
        description: 'Image form',
    },
    invalidImageUrl: {
        id: 'invalidImageUrl',
        defaultMessage: 'Invalid URL',
        description: 'Image form',
    },
    imageUploadFailed: {
        id: 'imageUploadFailed',
        defaultMessage: 'Image upload failed',
        description: 'Image form',
    },
    removeTranslation: {
        id: 'removeTranslation',
        defaultMessage: 'Remove Translation',
    },
    bold: { id: 'bold', defaultMessage: 'Bold' },
    italic: { id: 'italic', defaultMessage: 'Italic' },
    strike: { id: 'strike', defaultMessage: 'Strike' },
    textBlock: { id: 'textBlock', defaultMessage: 'Text Block' },
    h1: { id: 'h1', defaultMessage: 'Large Heading' },
    h2: { id: 'h2', defaultMessage: 'Medium Heading' },
    h3: { id: 'h3', defaultMessage: 'Small Heading' },
    orderList: { id: 'orderList', defaultMessage: 'Numbered List' },
    bulletList: { id: 'bulletList', defaultMessage: 'Bullet List' },
    divider: { id: 'divider', defaultMessage: 'Divider' },
    link: { id: 'link', defaultMessage: 'Web Link' },
    unlink: { id: 'unlink', defaultMessage: 'Remove Link' },
    emergencyUndo: { id: 'emergencyUndo', defaultMessage: 'Emergency Recover' },
    undo: { id: 'undo', defaultMessage: 'Undo' },
    popOutEditor: { id: 'popOutEditor', defaultMessage: 'Expand Editor' },
    headline: { id: 'headline', defaultMessage: 'Headline' },
    sms: { id: 'sms', defaultMessage: 'SMS Message' },
    imageIndex: {
        id: 'imageIndex',
        defaultMessage: 'Image {index}/{count}',
        description: 'Image form',
    },
    deleteImage: {
        id: 'deleteImage',
        defaultMessage: 'Remove Image',
        description: 'Image form',
    },
    charactersRemaining: {
        id: 'charactersRemaining',
        defaultMessage: 'Characters remaining',
        description: 'Image form',
    },
    sectionWithOvercount: {
        id: 'sectionWithOvercount',
        defaultMessage: 'The {section} section has at least one field with an exceeded character limit.',
        description: 'Menu item with overcount',
    },
    sectionListWithOvercount: {
        id: 'sectionListWithOvercount',
        defaultMessage: 'The {sectionList} sections have fields with exceeded character limits.',
        description: 'Menu items with overcount',
    },
    day: { id: 'day', defaultMessage: 'Day' },
    avalancheProblemType: {
        id: 'avalancheProblemType',
        defaultMessage: 'Avalanche Problem Type',
    },
    distribution: { id: 'distribution', defaultMessage: 'Distribution' },
    required: { id: 'required', defaultMessage: 'Required' },
    aspectElevationsRequired: {
        id: 'aspectElevationsRequired',
        defaultMessage: 'Aspects and elevations are Required',
        description: 'validation',
    },
    deleteTranslationConfirmation: {
        id: 'deleteTranslationConfirmation',
        defaultMessage: 'Are you sure you want to delete this translation?',
        description: 'Delete confirmation modal',
    },
    progress: {
        id: 'progress',
        defaultMessage: 'Progress',
        description: 'Stat for progress bar',
    },
    alpine: {
        id: 'alpine',
        defaultMessage: 'Alpine',
        description: 'Danger Ratings Dropdown',
    },
    treeline: {
        id: 'treeline',
        defaultMessage: 'Treeline',
        description: 'Danger Ratings Dropdown',
    },
    belowTreeLine: {
        id: 'belowTreeLine',
        defaultMessage: 'Below Tree Line',
        description: 'Danger Ratings Dropdown',
    },
    extreme: {
        id: 'extreme',
        defaultMessage: 'Extreme',
        description: 'Danger Ratings Dropdown',
    },
    high: {
        id: 'high',
        defaultMessage: 'High',
        description: 'Danger Ratings Dropdown',
    },
    considerable: {
        id: 'considerable',
        defaultMessage: 'Considerable',
        description: 'Danger Ratings Dropdown',
    },
    moderate: {
        id: 'moderate',
        defaultMessage: 'Moderate',
        description: 'Danger Ratings Dropdown',
    },
    low: {
        id: 'low',
        defaultMessage: 'Low',
        description: 'Danger Ratings Dropdown',
    },
    noRating: {
        id: 'noRating',
        defaultMessage: 'No Rating',
        description: 'Danger Ratings Dropdown',
    },
    spring: {
        id: 'spring',
        defaultMessage: 'Spring',
        description: 'Danger Ratings Dropdown',
    },
    earlySeason: {
        id: 'earlySeason',
        defaultMessage: 'Early Season',
        description: 'Danger Ratings Dropdown',
    },
    noForecast: {
        id: 'noForecast',
        defaultMessage: 'No Forecast',
        description: 'Danger Ratings Dropdown',
    },
    'highwayHazard.none': {
        id: 'highwayHazard.none',
        defaultMessage: 'None',
        description: 'Highway Hazard Dropdown',
    },
    'highwayHazard.noRating': {
        id: 'highwayHazard.noRating',
        defaultMessage: 'No Rating',
        description: 'Highway Hazard Dropdown',
    },
    'highwayHazard.notice': {
        id: 'highwayHazard.notice',
        defaultMessage: 'Notice',
        description: 'Highway Hazard Dropdown',
    },
    'highwayHazard.caution': {
        id: 'highwayHazard.caution',
        defaultMessage: 'Caution',
        description: 'Highway Hazard Dropdown',
    },
    'highwayHazard.warning': {
        id: 'highwayHazard.warning',
        defaultMessage: 'Warning',
        description: 'Highway Hazard Dropdown',
    },
    guidance: { id: 'guidance', defaultMessage: 'Guidance', description: 'Avalanche Problem form' },
    almostCertain: { id: 'almostCertain', defaultMessage: 'almost certain', description: 'Avalanche problem form' },
    veryLikely: { id: 'veryLikely', defaultMessage: 'very likely', description: 'Avalanche problem form' },
    likely: { id: 'Likely', defaultMessage: 'likely', description: 'Avalanche problem form' },
    unlikely: { id: 'Unlikely', defaultMessage: 'unlikely', description: 'Avalanche problem form' },
    possible: { id: 'Possible', defaultMessage: 'possible', description: 'Avalanche problem form' },
    possible_unlikely: {
        id: 'possible_unlikely',
        defaultMessage: 'possible–unlikely',
        description: 'Avalanche problem form',
    },
    likely_possible: {
        id: 'likely_possible',
        defaultMessage: 'likely–possible',
        description: 'Avalanche problem form',
    },
    veryLikely_likely: {
        id: 'veryLikely_likely',
        defaultMessage: 'very likely–likely',
    },
    certain: {
        id: 'certain',
        defaultMessage: 'certain',
        description: 'Avalanche problem form',
    },
    certain_veryLikely: {
        id: 'certain_veryLikely',
        defaultMessage: 'certain–very likely',
        description: 'Avalanche problem form',
    },
    hazardLikelihoodLabel: {
        id: 'hazardLikelihoodLabel',
        defaultMessage: 'Likelihood of Avalanches',
        description: 'hazard chart x axis label',
    },
    hazardSizeLabel: {
        id: 'hazardSizeLabel',
        defaultMessage: 'Destructive Avalanche Size',
        description: 'hazard chart y axis label',
    },
    NA: {
        id: 'NA',
        defaultMessage: 'N/A',
        description: 'accessible hazard chart display',
    },
    hazardRequired: { id: 'hazardRequired', defaultMessage: 'Hazard chart required', description: 'hazard validation' },
    documentation: { id: 'documentation', defaultMessage: 'Documentation', description: 'Avalanche problem form' },
    sensitivity: { id: 'sensitivity', defaultMessage: 'Sensitivity', description: 'Avalanche problem form' },
    typicalSize: { id: 'typicalSize', defaultMessage: 'Typical Size', description: 'Avalanche problem form' },
    typicalDepth: { id: 'typicalDepth', defaultMessage: 'Typical Depth', description: 'Avalanche problem form' },
    dryLoose: { id: 'dryLoose', defaultMessage: 'Dry Loose', description: 'Avalanche problem form' },
    wetLoose: { id: 'wetLoose', defaultMessage: 'Wet Loose', description: 'Avalanche problem form' },
    stormSlab: { id: 'stormslab', defaultMessage: 'Storm Slab', description: 'Avalanche problem form' },
    windSlab: { id: 'windslab', defaultMessage: 'Wind Slab', description: 'Avalanche problem form' },
    persistentSlab: { id: 'persistentSlab', defaultMessage: 'Persistent Slab', description: 'Avalanche problem form' },
    deepPersistentSlab: {
        id: 'deepPersistentSlab',
        defaultMessage: 'Deep Persistent Slab',
    },
    wetSlab: { id: 'wetSlab', defaultMessage: 'Wet Slab', description: 'Avalanche problem form' },
    drySlab: { id: 'drySlab', defaultMessage: 'Dry Slab', description: 'Avalanche problem form' },
    cornice: { id: 'cornice', defaultMessage: 'Cornice', description: 'Avalanche problem form' },
    isolated: { id: 'isolated', defaultMessage: 'Isolated', description: 'Avalanche problem form' },
    specific: { id: 'specific', defaultMessage: 'Specific', description: 'Avalanche problem form' },
    widespread: { id: 'widespread', defaultMessage: 'Widespread', description: 'Avalanche problem form' },
    unreactive: { id: 'Unreactive', defaultMessage: 'Unreactive', description: 'Avalanche problem form' },
    stubborn: { id: 'Stubborn', defaultMessage: 'Stubborn', description: 'Avalanche problem form' },
    reactive: { id: 'Reactive', defaultMessage: 'Reactive', description: 'Avalanche problem form' },
    touchy: { id: 'Touchy', defaultMessage: 'Touchy', description: 'Avalanche problem form' },
    observation: { id: 'Observation', defaultMessage: 'Observation', description: 'Avalanche problem form' },
    notRequired: {
        id: 'notRequired',
        defaultMessage: 'Not Required',
        description: 'status for review notes',
    },
    lowPriority: {
        id: 'lowPriority',
        defaultMessage: 'Low Priority',
        description: 'status for review notes',
    },
    highPriority: {
        id: 'highPriority',
        defaultMessage: 'High Priority',
        description: 'status for review notes',
    },
    reviewed: {
        id: 'reviewed',
        defaultMessage: 'Reviewed',
        description: 'status for review notes',
    },
    copyFromHeadline: {
        id: 'copyFromHeadline',
        defaultMessage: 'Copy from headline',
        description: '"Copy from headline" button',
    },
    terrainAndTravelAdvice: {
        id: 'terrainAndTravelAdvice',
        defaultMessage: 'Terrain and Travel Advice',
    },
    statementLimit: {
        id: 'statementLimit',
        defaultMessage:
            'Statement limit has been reached. Remove a selected statement before attempting to add a new one',
        description: 'Statement component limit messages',
    },
    filter: { id: 'filter', defaultMessage: 'Filter', description: 'Tag Search input' },
    tagSearch: {
        id: 'tagSearch',
        defaultMessage: 'Tag Search using keywords or tags',
        description: 'Tag Search input',
    },
    reviewStatus: {
        id: 'reviewStatus',
        defaultMessage: 'Review Status',
        description: 'Review Status Stat',
    },
    'avalancheProblemTypes.titles.name': { id: 'avalancheProblemTypes.titles.name', defaultMessage: 'Name' },
    'avalancheProblemTypes.titles.description': {
        id: 'avalancheProblemTypes.titles.description',
        defaultMessage: 'Description',
    },
    'avalancheProblemTypes.titles.formation': {
        id: 'avalancheProblemTypes.titles.formation',
        defaultMessage: 'Formation',
    },
    'avalancheProblemTypes.titles.persistence': {
        id: 'avalancheProblemTypes.titles.persistence',
        defaultMessage: 'Persistence',
    },
    'avalancheProblemTypes.dryLoose.name': { id: 'avalancheProblemTypes.dryLoose.name', defaultMessage: 'Dry Loose' },
    'avalancheProblemTypes.dryLoose.description': {
        id: 'avalancheProblemTypes.dryLoose.description',
        defaultMessage: 'Cohesionless dry snow starting from a point. Also called a sluff or point release.',
    },
    'avalancheProblemTypes.dryLoose.formation': {
        id: 'avalancheProblemTypes.dryLoose.formation',
        defaultMessage:
            'Surface layers of new snow crystals that lack cohesion, or surface layers of faceted snow grains that lose cohesion.',
    },
    'avalancheProblemTypes.dryLoose.persistence': {
        id: 'avalancheProblemTypes.dryLoose.persistence',
        defaultMessage:
            'Generally lasts hours to days when associated with new snow, and longer when associated with facets.',
    },
    'avalancheProblemTypes.wetLoose.name': { id: 'avalancheProblemTypes.wetLoose.name', defaultMessage: 'Wet Loose' },
    'avalancheProblemTypes.wetLoose.description': {
        id: 'avalancheProblemTypes.wetLoose.description',
        defaultMessage: 'Cohesionless wet snow starting from a point. Also called a sluff or point release.',
    },
    'avalancheProblemTypes.wetLoose.formation': {
        id: 'avalancheProblemTypes.wetLoose.formation',
        defaultMessage: 'Snow becomes wet and cohesionless from melting or liquid precipitation.',
    },
    'avalancheProblemTypes.wetLoose.persistence': {
        id: 'avalancheProblemTypes.wetLoose.persistence',
        defaultMessage: 'Persistence correlates with warm air temperatures, wet snow or rain, and/ or solar radiation.',
    },
    'avalancheProblemTypes.stormSlab.name': {
        id: 'avalancheProblemTypes.stormSlab.name',
        defaultMessage: 'Storm Slab',
    },
    'avalancheProblemTypes.stormSlab.description': {
        id: 'avalancheProblemTypes.stormSlab.description',
        defaultMessage: 'Cohesive slab of soft new snow. Also called a direct-action avalanche.',
    },
    'avalancheProblemTypes.stormSlab.formation': {
        id: 'avalancheProblemTypes.stormSlab.formation',
        defaultMessage:
            'Cohesive slab of new snow creates short-term instability within the storm snow or at the old snow interface  Wind transport of falling snow or soft surface snow.',
    },
    'avalancheProblemTypes.stormSlab.persistence': {
        id: 'avalancheProblemTypes.stormSlab.persistence',
        defaultMessage:
            'Peaks during periods of intense precipitation and tends to stabilize within hours or days following.',
    },
    'avalancheProblemTypes.windSlab.name': { id: 'avalancheProblemTypes.windSlab.name', defaultMessage: 'Wind Slab' },
    'avalancheProblemTypes.windSlab.description': {
        id: 'avalancheProblemTypes.windSlab.description',
        defaultMessage: 'Cohesive slab of locally deep, wind-deposited snow.',
    },
    'avalancheProblemTypes.windSlab.formation': {
        id: 'avalancheProblemTypes.windSlab.formation',
        defaultMessage:
            'Wind action breaks snow crystals into smaller particles and packs them into a cohesive slab overlying a nonpersistent weak layer.',
    },
    'avalancheProblemTypes.windSlab.persistence': {
        id: 'avalancheProblemTypes.windSlab.persistence',
        defaultMessage:
            'Peaks during periods of intense wind loading, and tends to stabilize within several days following. Cold air temperatures can extend the persistence.',
    },
    'avalancheProblemTypes.persistentSlab.name': {
        id: 'avalancheProblemTypes.persistentSlab.name',
        defaultMessage: 'Persistent Slab',
    },
    'avalancheProblemTypes.persistentSlab.description': {
        id: 'avalancheProblemTypes.persistentSlab.description',
        defaultMessage:
            'Cohesive slab of old and/or new snow that is poorly bonded to a persistent weak layer and does not strengthen, or strengthens slowly over time. Structure is conducive to failure initiation and crack propagation.',
    },
    'avalancheProblemTypes.persistentSlab.formation': {
        id: 'avalancheProblemTypes.persistentSlab.formation',
        defaultMessage:
            'Weak layer forms on the snow surface and is buried by new snow. The overlying slab builds incrementally over several storm cycles until reaching critical threshold for release.',
    },
    'avalancheProblemTypes.persistentSlab.persistence': {
        id: 'avalancheProblemTypes.persistentSlab.persistence',
        defaultMessage:
            'Often builds slowly and then activates within a short period of time. Can persist for weeks or months but generally disappears within six weeks.',
    },
    'avalancheProblemTypes.deepPersistentSlab.name': {
        id: 'avalancheProblemTypes.deepPersistentSlab.name',
        defaultMessage: 'Deep Persistent Slab',
    },
    'avalancheProblemTypes.deepPersistentSlab.description': {
        id: 'avalancheProblemTypes.deepPersistentSlab.description',
        defaultMessage:
            'Thick, hard cohesive slab of old snow overlying an early-season persistent weak layer located in the lower snowpack or near the ground. Structure is conducive to failure initiation and crack propagation. Typically characterized by low likelihood and large destructive size.',
    },
    'avalancheProblemTypes.deepPersistentSlab.formation': {
        id: 'avalancheProblemTypes.deepPersistentSlab.formation',
        defaultMessage:
            'Weak layer metamorphoses within the snowpack forming facets adjacent to an early-season ice crust, depth hoar at the base of the snowpack, or facets at the snow-glacier ice interface. The overlying slab builds incrementally over many storm cycles until reaching critical threshold for release.',
    },
    'avalancheProblemTypes.deepPersistentSlab.persistence': {
        id: 'avalancheProblemTypes.deepPersistentSlab.persistence',
        defaultMessage:
            'Develops early in the winter and is characterized by periods of activity followed by periods of dormancy, then activity again. This on/off pattern can persist for the entire season until the snowpack has melted.',
    },
    'avalancheProblemTypes.wetSlab.name': { id: 'avalancheProblemTypes.wetSlab.name', defaultMessage: 'Wet Slab' },
    'avalancheProblemTypes.wetSlab.description': {
        id: 'avalancheProblemTypes.wetSlab.description',
        defaultMessage: 'Cohesive slab of moist to wet snow that results in dense debris with no powder cloud.',
    },
    'avalancheProblemTypes.wetSlab.formation': {
        id: 'avalancheProblemTypes.wetSlab.formation',
        defaultMessage:
            'Slab or weak layer is affected by liquid water which decreases cohesion. Crack propagation occurs before a total loss of cohesion produces a wet loose avalanche problem.',
    },
    'avalancheProblemTypes.wetSlab.persistence': {
        id: 'avalancheProblemTypes.wetSlab.persistence',
        defaultMessage:
            'Peaks during periods of rainfall or extended warm air temperatures. Persists until either the snowpack refreezes or turns to slush.',
    },
    'avalancheProblemTypes.drySlab.name': { id: 'avalancheProblemTypes.drySlab.name', defaultMessage: 'Dry Slab' },
    'avalancheProblemTypes.drySlab.description': {
        id: 'avalancheProblemTypes.drySlab.description',
        defaultMessage:
            'Entire snowpack glides downslope then cracks, then continues to glide downslope until it releases a full depth avalanche.',
    },
    'avalancheProblemTypes.drySlab.formation': {
        id: 'avalancheProblemTypes.drySlab.formation',
        defaultMessage:
            'Entire snowpack glides downslope then cracks, then continues to glide downslope until it releases a full depth avalanche.',
    },
    'avalancheProblemTypes.drySlab.persistence': {
        id: 'avalancheProblemTypes.drySlab.persistence',
        defaultMessage:
            'Can appear at any time in the winter and persists for the remainder of the winter. Avalanche activity is almost impossible to predict.',
    },
    'avalancheProblemTypes.cornice.name': { id: 'avalancheProblemTypes.cornice.name', defaultMessage: 'Cornice' },
    'avalancheProblemTypes.cornice.description': {
        id: 'avalancheProblemTypes.cornice.description',
        defaultMessage: 'Overhanging mass of dense, wind deposited snow jutting out over a drop-off in the terrain.',
    },
    'avalancheProblemTypes.cornice.formation': {
        id: 'avalancheProblemTypes.cornice.formation',
        defaultMessage:
            'Wind transport of falling snow or soft surface snow develops a horizontal, overhanging build out of dense snow on the leeward side of sharp terrain breaks.',
    },
    'avalancheProblemTypes.cornice.persistence': {
        id: 'avalancheProblemTypes.cornice.persistence',
        defaultMessage:
            'Persists all winter on ridge crests, and tends to collapse spontaneously during periods of warming, or following intense wind loading events.',
    },
    'distribution.titles.distribution': { id: 'distribution.titles.distribution', defaultMessage: 'Distribution' },
    'distribution.titles.spatialDensity': {
        id: 'distribution.titles.spatialDensity',
        defaultMessage: 'Spatial Density',
    },
    'distribution.titles.evidence': { id: 'distribution.titles.evidence', defaultMessage: 'Evidence' },
    'distribution.isolated.distribution': { id: 'distribution.isolated.distribution', defaultMessage: 'Isolated' },
    'distribution.isolated.spatialDensity': {
        id: 'distribution.isolated.spatialDensity',
        defaultMessage: 'The avalanche problem is spotty and found in only a few terrain features.',
    },
    'distribution.isolated.evidence': {
        id: 'distribution.isolated.evidence',
        defaultMessage: 'Evidence is rare and hard to find.',
    },
    'distribution.specific.distribution': { id: 'distribution.specific.distribution', defaultMessage: 'Specific' },
    'distribution.specific.spatialDensity': {
        id: 'distribution.specific.spatialDensity',
        defaultMessage: 'The avalanche problem exists in terrain features with common characteristics.',
    },
    'distribution.specific.evidence': {
        id: 'distribution.specific.evidence',
        defaultMessage: 'Evidence exists but is not always obvious.',
    },
    'distribution.widespread.distribution': {
        id: 'distribution.widespread.distribution',
        defaultMessage: 'Widespread',
    },
    'distribution.widespread.spatialDensity': {
        id: 'distribution.widespread.spatialDensity',
        defaultMessage: 'The avalanche problem is found in many locations and terrain features.',
    },
    'distribution.widespread.evidence': {
        id: 'distribution.widespread.evidence',
        defaultMessage: 'Evidence is everywhere and easy to find.',
    },
    'distribution.comment.distribution': { id: 'distribution.comment.distribution', defaultMessage: 'Comment' },
    'distribution.comment.spatialDensity': {
        id: 'distribution.comment.spatialDensity',
        defaultMessage: 'How is the evidence distributed?',
    },
    'distribution.comment.evidence': { id: 'distribution.comment.evidence', defaultMessage: 'How hard is it to find?' },
    'destructiveSize.1.size': { id: 'destructiveSize.1.size', defaultMessage: '1' },
    'destructiveSize.1.potential': {
        id: 'destructiveSize.1.potential',
        defaultMessage: 'Relatively harmless to people.',
    },
    'destructiveSize.1.mass': { id: 'destructiveSize.1.mass', defaultMessage: '<10 t' },
    'destructiveSize.1.pressure': { id: 'destructiveSize.1.pressure', defaultMessage: '1 kPa' },
    'destructiveSize.1.length': { id: 'destructiveSize.1.length', defaultMessage: '10 m' },
    'destructiveSize.2.size': { id: 'destructiveSize.2.size', defaultMessage: '2' },
    'destructiveSize.2.potential': {
        id: 'destructiveSize.2.potential',
        defaultMessage: 'Could bury, injure, or kill a person.',
    },
    'destructiveSize.2.mass': { id: 'destructiveSize.2.mass', defaultMessage: '10^2 t' },
    'destructiveSize.2.pressure': { id: 'destructiveSize.2.pressure', defaultMessage: '10 kPa' },
    'destructiveSize.2.length': { id: 'destructiveSize.2.length', defaultMessage: '100 m' },
    'destructiveSize.3.size': { id: 'destructiveSize.3.size', defaultMessage: '3' },
    'destructiveSize.3.potential': {
        id: 'destructiveSize.3.potential',
        defaultMessage:
            'Could bury or destroy a car, damage a truck, destroy a wood frame house, or break a few trees.',
    },
    'destructiveSize.3.mass': { id: 'destructiveSize.3.mass', defaultMessage: '10^3 t' },
    'destructiveSize.3.pressure': { id: 'destructiveSize.3.pressure', defaultMessage: '100 kPa' },
    'destructiveSize.3.length': { id: 'destructiveSize.3.length', defaultMessage: '1000m' },
    'destructiveSize.4.size': { id: 'destructiveSize.4.size', defaultMessage: '4' },
    'destructiveSize.4.potential': {
        id: 'destructiveSize.4.potential',
        defaultMessage:
            'Could destroy a railway car, large truck, several buildings, or a forest area of approximately 4 hectares.',
    },
    'destructiveSize.4.mass': { id: 'destructiveSize.4.mass', defaultMessage: '10^4 t' },
    'destructiveSize.4.pressure': { id: 'destructiveSize.4.pressure', defaultMessage: '500 kPa' },
    'destructiveSize.4.length': { id: 'destructiveSize.4.length', defaultMessage: '2000m' },
    'destructiveSize.5.size': { id: 'destructiveSize.5.size', defaultMessage: '5' },
    'destructiveSize.5.potential': {
        id: 'destructiveSize.5.potential',
        defaultMessage:
            'Largest snow avalanche known. Could destroy a village or a forest area of approximately 40 hectares.',
    },
    'destructiveSize.5.mass': { id: 'destructiveSize.5.mass', defaultMessage: '10^5 t' },
    'destructiveSize.5.pressure': { id: 'destructiveSize.5.pressure', defaultMessage: '1000 kPa' },
    'destructiveSize.5.length': { id: 'destructiveSize.5.length', defaultMessage: '3000m' },
    'destructiveSize.titles.size': { id: 'destructiveSize.titles.size', defaultMessage: 'Destructive Size' },
    'destructiveSize.titles.potential': {
        id: 'destructiveSize.titles.potential',
        defaultMessage: 'Avalanche Destructive Potential',
    },
    'destructiveSize.titles.mass': { id: 'destructiveSize.titles.mass', defaultMessage: 'Typical Mass' },
    'destructiveSize.titles.pressure': {
        id: 'destructiveSize.titles.pressure',
        defaultMessage: 'Typical Impact Pressure',
    },
    'destructiveSize.titles.length': { id: 'destructiveSize.titles.length', defaultMessage: 'Typical Path Length' },
    'sensitivity.titles.sensitivity': { id: 'sensitivity.titles.sensitivity', defaultMessage: 'Sensitivity' },
    'sensitivity.titles.release': { id: 'sensitivity.titles.release', defaultMessage: 'Natural Releases' },
    'sensitivity.titles.humanTriggers': { id: 'sensitivity.titles.humanTriggers', defaultMessage: 'Human Triggers' },
    'sensitivity.titles.size': { id: 'sensitivity.titles.size', defaultMessage: 'Explosive Triggers - Size' },
    'sensitivity.titles.result': { id: 'sensitivity.titles.result', defaultMessage: 'Explosive Triggers - Result' },
    'sensitivity.titles.corniceTriggers': {
        id: 'sensitivity.titles.corniceTriggers',
        defaultMessage: 'Cornice Triggers',
    },
    'sensitivity.unreactive.sensitivity': { id: 'sensitivity.unreactive.sensitivity', defaultMessage: 'Unreactive' },
    'sensitivity.unreactive.release': { id: 'sensitivity.unreactive.release', defaultMessage: 'No avalanches' },
    'sensitivity.unreactive.humanTriggers': {
        id: 'sensitivity.unreactive.humanTriggers',
        defaultMessage: 'No avalanches',
    },
    'sensitivity.unreactive.explosiveTriggersSize': {
        id: 'sensitivity.unreactive.explosiveTriggersSize',
        defaultMessage: 'Very large explosives in many locations',
    },
    'sensitivity.unreactive.explosiveTriggersResult': {
        id: 'sensitivity.unreactive.explosiveTriggersResult',
        defaultMessage: 'No slab',
    },
    'sensitivity.unreactive.corniceTriggers': {
        id: 'sensitivity.unreactive.corniceTriggers',
        defaultMessage: 'No slab from very large cornice fall',
    },
    'sensitivity.stubborn.sensitivity': { id: 'sensitivity.stubborn.sensitivity', defaultMessage: 'Stubborn' },
    'sensitivity.stubborn.release': { id: 'sensitivity.stubborn.release', defaultMessage: 'Few' },
    'sensitivity.stubborn.humanTriggers': {
        id: 'sensitivity.stubborn.humanTriggers',
        defaultMessage: 'Difficult to trigger',
    },
    'sensitivity.stubborn.explosiveTriggersSize': {
        id: 'sensitivity.stubborn.explosiveTriggersSize',
        defaultMessage: 'Large explosive and air blasts, often in several locations',
    },
    'sensitivity.stubborn.explosiveTriggersResult': {
        id: 'sensitivity.stubborn.explosiveTriggersResult',
        defaultMessage: 'Some',
    },
    'sensitivity.stubborn.corniceTriggers': { id: 'sensitivity.stubborn.corniceTriggers', defaultMessage: 'Large' },
    'sensitivity.reactive.sensitivity': { id: 'sensitivity.reactive.sensitivity', defaultMessage: 'Reactive' },
    'sensitivity.reactive.release': { id: 'sensitivity.reactive.release', defaultMessage: 'Several' },
    'sensitivity.reactive.humanTriggers': {
        id: 'sensitivity.reactive.humanTriggers',
        defaultMessage: 'Easy to trigger with ski cuts',
    },
    'sensitivity.reactive.explosiveTriggersSize': {
        id: 'sensitivity.reactive.explosiveTriggersSize',
        defaultMessage: 'Single hand charge',
    },
    'sensitivity.reactive.explosiveTriggersResult': {
        id: 'sensitivity.reactive.explosiveTriggersResult',
        defaultMessage: 'Many',
    },
    'sensitivity.reactive.corniceTriggers': { id: 'sensitivity.reactive.corniceTriggers', defaultMessage: 'Medium' },
    'sensitivity.touchy.sensitivity': { id: 'sensitivity.touchy.sensitivity', defaultMessage: 'Touchy' },
    'sensitivity.touchy.release': { id: 'sensitivity.touchy.release', defaultMessage: 'Numerous' },
    'sensitivity.touchy.humanTriggers': {
        id: 'sensitivity.touchy.humanTriggers',
        defaultMessage: 'Triggering almost certain',
    },
    'sensitivity.touchy.explosiveTriggersSize': {
        id: 'sensitivity.touchy.explosiveTriggersSize',
        defaultMessage: 'Any size',
    },
    'sensitivity.touchy.explosiveTriggersResult': {
        id: 'sensitivity.touchy.explosiveTriggersResult',
        defaultMessage: 'Numerous',
    },
    'sensitivity.touchy.corniceTriggers': { id: 'sensitivity.touchy.corniceTriggers', defaultMessage: 'Any aize' },
    'sensitivity.observation.sensitivity': {
        id: 'sensitivity.observation.sensitivity',
        defaultMessage: 'Description of Observation',
    },
    'sensitivity.observation.release': {
        id: 'sensitivity.observation.release',
        defaultMessage: 'Natural avalanche occurrence',
    },
    'sensitivity.observation.humanTriggers': {
        id: 'sensitivity.observation.humanTriggers',
        defaultMessage: 'Ease of triggering by a single human',
    },
    'sensitivity.observation.explosiveTriggersSize': {
        id: 'sensitivity.observation.explosiveTriggersSize',
        defaultMessage: 'Size of explosive and effect',
    },
    'sensitivity.observation.explosiveTriggersResult': {
        id: 'sensitivity.observation.explosiveTriggersResult',
        defaultMessage: 'Size of explosive and effect',
    },
    'sensitivity.observation.corniceTriggers': {
        id: 'sensitivity.observation.corniceTriggers',
        defaultMessage: 'Size of cornice that will trigger a slab',
    },
    'likelihood.titles.distribution': { id: 'likelihood.titles.distribution', defaultMessage: 'Spatial Distribution' },
    'likelihood.titles.unreactive': { id: 'likelihood.titles.unreactive', defaultMessage: 'Unreactive' },
    'likelihood.titles.stubborn': { id: 'likelihood.titles.stubborn', defaultMessage: 'Stubborn' },
    'likelihood.titles.reactive': { id: 'likelihood.titles.reactive', defaultMessage: 'Reactive' },
    'likelihood.titles.touchy': { id: 'likelihood.titles.touchy', defaultMessage: 'Touchy' },
    'likelihood.widespread.distribution': { id: 'likelihood.widespread.distribution', defaultMessage: 'Widespread' },
    'likelihood.widespread.unreactive': { id: 'likelihood.widespread.unreactive', defaultMessage: 'Unlikely' },
    'likelihood.widespread.stubborn': { id: 'likelihood.widespread.stubborn', defaultMessage: 'Possible' },
    'likelihood.widespread.reactive': { id: 'likelihood.widespread.reactive', defaultMessage: 'Very Likely' },
    'likelihood.widespread.touchy': { id: 'likelihood.widespread.touchy', defaultMessage: 'Almost Certain' },
    'likelihood.specific.distribution': { id: 'likelihood.specific.distribution', defaultMessage: 'Specific' },
    'likelihood.specific.unreactive': { id: 'likelihood.specific.unreactive', defaultMessage: 'Unlikely' },
    'likelihood.specific.stubborn': { id: 'likelihood.specific.stubborn', defaultMessage: 'Possible' },
    'likelihood.specific.reactive': { id: 'likelihood.specific.reactive', defaultMessage: 'Likely' },
    'likelihood.specific.touchy': { id: 'likelihood.specific.touchy', defaultMessage: 'Very Likely' },
    'likelihood.isolated.distribution': { id: 'likelihood.isolated.distribution', defaultMessage: 'Isolated' },
    'likelihood.isolated.unreactive': { id: 'likelihood.isolated.unreactive', defaultMessage: 'Unlikely' },
    'likelihood.isolated.stubborn': { id: 'likelihood.isolated.stubborn', defaultMessage: 'Unlikely' },
    'likelihood.isolated.reactive': { id: 'likelihood.isolated.reactive', defaultMessage: 'Possible' },
    'likelihood.isolated.touchy': { id: 'likelihood.isolated.touchy', defaultMessage: 'Likely' },
    'hazardChart.likelihood.unlikely': { id: 'hazardChart.likelihood.unlikely', defaultMessage: 'Unlikely' },
    'hazardChart.likelihood.possible': { id: 'hazardChart.likelihood.possible', defaultMessage: 'Possible' },
    'hazardChart.likelihood.likely': { id: 'hazardChart.likelihood.likely', defaultMessage: 'Likely' },
    'hazardChart.likelihood.veryLikely': { id: 'hazardChart.likelihood.veryLikely', defaultMessage: 'Very Likely' },
    'hazardChart.likelihood.almostCertain': {
        id: 'hazardChart.likelihood.almostCertain',
        defaultMessage: 'Almost Certain',
    },
    'confidence.titles.rating': { id: 'confidence.titles.rating', defaultMessage: 'Confidence Ratings' },
    'confidence.titles.definition': { id: 'confidence.titles.definition', defaultMessage: 'Definition' },
    'confidence.high.rating': { id: 'confidence.high.rating', defaultMessage: 'High' },
    'confidence.high.definition': {
        id: 'confidence.high.definition',
        defaultMessage:
            "The forecast is based on high-quality information and the nature of the issue makes it possible to render a solid judgement. A 'high confidence' rating does not imply fact or complete certainty however, and such judgements still carry the rtisk of being wrong.",
    },
    'confidence.moderate.rating': { id: 'confidence.moderate.rating', defaultMessage: 'Moderate' },
    'confidence.moderate.definition': {
        id: 'confidence.moderate.definition',
        defaultMessage:
            'The information used to produce the forecast is credibly sourced and plausible, but is not of adquate quality or sufficently corroborated to warrant a higher level of confidence.',
    },
    'confidence.low.rating': { id: 'confidence.low.rating', defaultMessage: 'Low' },
    'confidence.low.definition': {
        id: 'confidence.low.definition',
        defaultMessage:
            'The credibility or plausibility of the informaiton used to produce the forecast is questionable, or the information is too fragmented or poorly corroborated to make solid judgemens, or there are significant concerns regarding problems with the sources.',
    },
    'confidence.noRating.rating': { id: 'confidence.noRating.rating', defaultMessage: 'No Rating' },
    'dangerRating.titles.dangerLevel': { id: 'dangerRating.titles.dangerLevel', defaultMessage: 'Danger Level' },
    'dangerRating.titles.travelAdvice': { id: 'dangerRating.titles.travelAdvice', defaultMessage: 'Travel Advice' },
    'dangerRating.titles.likelihood': {
        id: 'dangerRating.titles.likelihood',
        defaultMessage: 'Likelihoods of Avalanches',
    },
    'dangerRating.titles.sizeDistribution': {
        id: 'dangerRating.titles.sizeDistribution',
        defaultMessage: 'Avalanche Size and Distribution',
    },
    'dangerRating.extreme.dangerLevel': { id: 'dangerRating.extreme.dangerLevel', defaultMessage: 'Extreme' },
    'dangerRating.extreme.travelAdvice': {
        id: 'dangerRating.extreme.travelAdvice',
        defaultMessage: 'Extraordinarily dangerous avalanche conditions. Avoid all avalanche terrain.',
    },
    'dangerRating.extreme.likelihood': {
        id: 'dangerRating.extreme.likelihood',
        defaultMessage: 'Natural and human-triggered avalanches certain.',
    },
    'dangerRating.extreme.sizeDistribution': {
        id: 'dangerRating.extreme.sizeDistribution',
        defaultMessage: 'Very large avalanches in many areas.',
    },
    'dangerRating.high.dangerLevel': { id: 'dangerRating.high.dangerLevel', defaultMessage: 'High' },
    'dangerRating.high.travelAdvice': {
        id: 'dangerRating.high.travelAdvice',
        defaultMessage: 'Very dangerous avalanche conditions. Travel in avalanche terrain not recommended.',
    },
    'dangerRating.high.likelihood': {
        id: 'dangerRating.high.likelihood',
        defaultMessage: 'Natural avalanches likely; human-triggered avalanches very likely.',
    },
    'dangerRating.high.sizeDistribution': {
        id: 'dangerRating.high.sizeDistribution',
        defaultMessage: 'Large avalanches in many areas; or very large avalanches in specific areas.',
    },
    'dangerRating.considerable.dangerLevel': {
        id: 'dangerRating.considerable.dangerLevel',
        defaultMessage: 'Considerable',
    },
    'dangerRating.considerable.travelAdvice': {
        id: 'dangerRating.considerable.travelAdvice',
        defaultMessage:
            'Dangerous avalanche conditions. Careful snowpack evaluation, cautious route-finding, and conservative decision-making essential.',
    },
    'dangerRating.considerable.likelihood': {
        id: 'dangerRating.considerable.likelihood',
        defaultMessage: 'Natural avalanches possible; human-triggered avalanches likely.',
    },
    'dangerRating.considerable.sizeDistribution': {
        id: 'dangerRating.considerable.sizeDistribution',
        defaultMessage:
            'Small avalanches in many areas; or large avalanches in specific areas; or very large avalanches in isolated areas.',
    },
    'dangerRating.moderate.dangerLevel': { id: 'dangerRating.moderate.dangerLevel', defaultMessage: 'Moderate' },
    'dangerRating.moderate.travelAdvice': {
        id: 'dangerRating.moderate.travelAdvice',
        defaultMessage:
            'Heightened avalanche conditions on specific terrain features. Evaluate snow and terrain carefully; identify features of concern.',
    },
    'dangerRating.moderate.likelihood': {
        id: 'dangerRating.moderate.likelihood',
        defaultMessage: 'Natural avalanches unlikely; human-triggered avalanches possible.',
    },
    'dangerRating.moderate.sizeDistribution': {
        id: 'dangerRating.moderate.sizeDistribution',
        defaultMessage: 'Small avalanches in specific areas; or large avalanches in isolated areas.',
    },
    'dangerRating.low.dangerLevel': { id: 'dangerRating.low.dangerLevel', defaultMessage: 'Low' },
    'dangerRating.low.travelAdvice': {
        id: 'dangerRating.low.travelAdvice',
        defaultMessage: 'Generally safe avalanche conditions. Watch for unstable snow on isolated terrain',
    },
    'dangerRating.low.likelihood': {
        id: 'dangerRating.low.likelihood',
        defaultMessage: 'Generally safe avalanche conditions. Watch for unstable snow on isolated terrain features.',
    },
    'dangerRating.low.sizeDistribution': {
        id: 'dangerRating.low.sizeDistribution',
        defaultMessage: 'Small avalanches in isolated areas or extreme terrain.',
    },
    'dangerRating.noRating.dangerLevel': { id: 'dangerRating.noRating.dangerLevel', defaultMessage: 'No Rating' },
    'dangerRating.noRating.travelAdvice': {
        id: 'dangerRating.noRating.travelAdvice',
        defaultMessage:
            'This elevation band does not exist in the region, or there’s not yet enough snow at this elevation band to produce avalanches.',
    },
    'dangerRating.noRating.likelihood': { id: 'dangerRating.noRating.likelihood', defaultMessage: ' ' },
    'dangerRating.noRating.sizeDistribution': { id: 'dangerRating.noRating.sizeDistribution', defaultMessage: ' ' },
    'Friday.Nowcast': { id: 'Friday.Nowcast', defaultMessage: 'Friday Nowcast', description: 'Avalanche forcast days' },
    'Friday.Forecast': {
        id: 'Friday.Forecast',
        defaultMessage: 'Friday Forecast',
        description: 'Avalanche forecast days',
    },
    'Saturday.Nowcast': {
        id: 'Saturday.Nowcast',
        defaultMessage: 'Saturday Nowcast',
        description: 'Avalanche forcast days',
    },
    'Saturday.Forecast': {
        id: 'Saturday.Forecast',
        defaultMessage: 'Saturday Forecast',
        description: 'Avalanche forecast days',
    },
    'Monday.Nowcast': { id: 'Monday.Nowcast', defaultMessage: 'Monday Nowcast', description: 'Avalanche forcast days' },
    'Monday.Forecast': {
        id: 'Monday.Forecast',
        defaultMessage: 'Monday Forecast',
        description: 'Avalanche forecast days',
    },
    'Tuesday.Nowcast': {
        id: 'Tuesday.Nowcast',
        defaultMessage: 'Tuesday Nowcast',
        description: 'Avalanche forcast days',
    },
    'Tuesday.Forecast': {
        id: 'Tuesday.Forecast',
        defaultMessage: 'Tuesday Forecast',
        description: 'Avalanche forecast days',
    },
    'Wednesday.Nowcast': {
        id: 'Wednesday.Nowcast',
        defaultMessage: 'Wednesday Nowcast',
        description: 'Avalanche forcast days',
    },
    'Wednesday.Forecast': {
        id: 'Wednesday.Forecast',
        defaultMessage: 'Wednesday Forecast',
        description: 'Avalanche forecast days',
    },
    'Thursday.Nowcast': {
        id: 'Thursday.Nowcast',
        defaultMessage: 'Thursday Nowcast',
        description: 'Avalanche forcast days',
    },
    'Thursday.Forecast': {
        id: 'Thursday.Forecast',
        defaultMessage: 'Thursday Forecast',
        description: 'Avalanche forecast days',
    },
    'Sunday.Nowcast': { id: 'Sunday.Nowcast', defaultMessage: 'Sunday Nowcast', description: 'Avalanche forcast days' },
    'Sunday.Forecast': {
        id: 'Sunday.Forecast',
        defaultMessage: 'Sunday Forecast',
        description: 'Avalanche forecast days',
    },
    translationRequired: {
        id: 'translationRequired',
        defaultMessage: 'Translation Required',
        description: 'tag for any text requiring translation',
    },
    'polygonList.titles.id': {
        id: 'polygonList.titles.id',
        defaultMessage: 'ID',
        description: 'Title for the ID column in the polygon list',
    },
    'polygonList.titles.name': {
        id: 'polygonList.titles.name',
        defaultMessage: 'Name',
        description: 'Title for the Name column in the polygon list',
    },
    'polygonList.titles.mountainRange': {
        id: 'polygonList.titles.mountainRange',
        defaultMessage: 'Mountain Range',
        description: 'Title for the Mountain Range column in the polygon list',
    },
    copyToNextDay: {
        id: 'copyToNextDay',
        defaultMessage: 'Copy To Next Day',
        description: 'More menu option in day card',
    },
    copyToAllDays: {
        id: 'copyToAllDays',
        defaultMessage: 'Copy To All Days',
        description: 'More menu option in day card',
    },
    reviewNote: {
        id: 'reviewNote',
        defaultMessage: 'Review Note',
        description: 'More menu option in day card',
    },
    polygonTable: {
        id: 'polygonTable',
        defaultMessage: 'Polygon Table',
        description: 'Enhanced accessibility polygon table',
    },
    polygonA11yTableId: {
        id: 'polygonA11yTableId',
        defaultMessage: 'ID',
        description: 'Enhanced accessibility polygon table',
    },
    polygonA11yTableName: {
        id: 'polygonA11yTableName',
        defaultMessage: 'Name',
        description: 'Enhanced accessibility polygon table',
    },
    polygonA11yTableMountainRange: {
        id: 'polygonA11yTableMountainRange',
        defaultMessage: 'Mountain Range',
        description: 'Enhanced accessibility polygon table',
    },
    polygonA11yTableCentroidLng: {
        id: 'polygonA11yTableCentroidLng',
        defaultMessage: 'Centroid Longitude',
        description: 'Enhanced accessibility polygon table',
    },
    polygonA11yTableCentroidLat: {
        id: 'polygonA11yTableCentroidLat',
        defaultMessage: 'Centroid Latitude',
        description: 'Enhanced accessibility polygon table',
    },
    polygonA11yTableLayer: {
        id: 'polygonA11yTableLayer',
        defaultMessage: 'In active layer',
        description: 'Enhanced accessibility polygon table',
    },
    polygonA11yTableCurrentProduct: {
        id: 'polygonA11yTableCurrentProduct',
        defaultMessage: 'Assigned Product',
        description: 'Enhanced accessibility polygon table',
    },
    polygonA11yTableInActiveProduct: {
        id: 'polygonA11yTableInActiveProduct',
        defaultMessage: 'In active product',
        description: 'Enhanced accessibility polygon table',
    },
    polygonA11yTableNoProduct: {
        id: 'polygonA11yTableNoProduct',
        defaultMessage: 'none',
        description: 'Enhanced accessibility polygon table',
    },
    hwyForecast: {
        id: 'hwyForecast',
        defaultMessage: 'Highway Forecasts',
        description: 'Highway Forecasts',
    },
    hwyDiscussion: {
        id: 'hwyDiscussion',
        defaultMessage: 'Highway Discussions',
        description: 'Highway Discussions',
    },
    mitigation: {
        id: 'mitigation',
        defaultMessage: 'Mitigations',
        description: 'Mitigation',
    },
    createHighwayForecast: {
        id: 'createHighwayForecast',
        defaultMessage: 'Set Up New Highway Forecast',
        description: 'Highway Forecasts page',
    },
    editHighwayForecast: {
        id: 'editHighwayForecast',
        defaultMessage: 'Edit Highway Forecast',
        description: 'Highway Forecasts page',
    },
    highwayForecastName: {
        id: 'highwayForecastName',
        defaultMessage: 'Highway Forecast Name',
        description: 'Highway Forecasts page',
    },
    noHighwayForecastsFound: {
        id: 'noHighwayForecastsFound',
        defaultMessage: 'No Highway Forecasts Found',
        description: 'Highway Forecasts page',
    },
    highwayForecastSaved: {
        id: 'highwayForecastSaved',
        defaultMessage: 'Highway Forecast Saved',
        description: 'Highway Forecasts page',
    },
    highwayForecastSaveError: {
        id: 'highwayForecastSaveError',
        defaultMessage: 'Highway forecast “{name}” could not be saved',
        description: 'Highway Forecasts page',
    },
    deleteHighwayForecast: {
        id: 'deleteHighwayForecast',
        defaultMessage: 'Delete Highway Forecast',
        description: 'Highway Forecasts page',
    },
    highwayForecastDeleted: {
        id: 'highwayForecastDeleted',
        defaultMessage: 'Highway Forecast Deleted',
        description: 'Highway Forecasts page',
    },
    deleteHwyForecast: {
        id: 'deleteHwyForecast',
        defaultMessage: 'Delete Highway Forecast',
        description: 'Highway Forecasts page',
    },
    yesConfirmDeleteHighwayForecast: {
        id: 'yesConfirmDeleteHighwayForecast',
        defaultMessage: 'Yes, Delete Highway Forecast',
        description: 'Highway Forecasts page',
    },
    deleteHighwayForecastConfirmation: {
        id: 'deleteHighwayForecastConfirmation',
        defaultMessage: 'Are you sure you want to delete this highway forecast?',
        description: 'Highway Forecasts page',
    },
    createHighwayDiscussion: {
        id: 'createHighwayDiscussion',
        defaultMessage: 'Create Highway Discussion',
        description: 'Highway Discussions page',
    },
    editHighwayDiscussion: {
        id: 'editHighwayDiscussion',
        defaultMessage: 'Edit Highway Discussion',
        description: 'Highway Discussions page',
    },
    highwayforecast: {
        id: 'highwayforecast',
        defaultMessage: 'Highway Forecasts',
        description: 'Archive Table',
    },
    highwaydiscussion: {
        id: 'highwayDiscussion',
        defaultMessage: 'Highway Discussions',
        description: 'Archive Table',
    },
    highwayDiscussionName: {
        id: 'highwayDiscussionName',
        defaultMessage: 'Highway Discussion Name',
        description: 'Highway Discussions page',
    },
    noHighwayDiscussionsFound: {
        id: 'noHighwayDiscussionsFound',
        defaultMessage: 'No Highway Discussions Found',
        description: 'Highway Discussions page',
    },
    highwayDiscussionSaved: {
        id: 'highwayDiscussionSaved',
        defaultMessage: 'Highway Discussion Saved',
        description: 'Highway Discussions page',
    },
    highwayDiscussionSaveError: {
        id: 'highwayDiscussionSaveError',
        defaultMessage: 'highway discussion “{name}” could not be saved',
        description: 'Highway Discussions page',
    },
    highwayDiscussionDeleted: {
        id: 'highwayDiscussionDeleted',
        defaultMessage: 'Highway Discussion Deleted',
        description: 'Highway Discussions page',
    },
    yesConfirmDeleteHighwayDiscussion: {
        id: 'yesConfirmDeleteHighwayDiscussion',
        defaultMessage: 'Yes, Delete Highway Discussion',
        description: 'Highway Discussions page',
    },
    deleteHighwayDiscussionConfirmation: {
        id: 'deleteHighwayDiscussionConfirmation',
        defaultMessage: 'Are you sure you want to delete this highway discussion?',
        description: 'Highway Discussions page',
    },
    deleteHighwayDiscussion: {
        id: 'deleteHighwayDiscussion',
        defaultMessage: 'Delete Highway Discussion',
        description: 'Highway Discussions page',
    },
    createMitigation: {
        id: 'createMitigation',
        defaultMessage: 'Create Mitigation',
        description: 'Highway Mitigation page',
    },
    editMitigation: {
        id: 'editMitigation',
        defaultMessage: 'Edit Mitigation',
        description: 'Highway Mitigation page',
    },
    mitigationName: {
        id: 'mitigationName',
        defaultMessage: 'Mitigation Name',
        description: 'Highway Mitigation page',
    },
    noMitigationsFound: {
        id: 'noMitigationsFound',
        defaultMessage: 'No Mitigations Found',
        description: 'Highway Mitigation page',
    },
    mitigationSaved: {
        id: 'mitigationSaved',
        defaultMessage: 'Mitigation Saved',
        description: 'Highway Mitigation page',
    },
    mitigationSaveError: {
        id: 'mitigationSaveError',
        defaultMessage: 'Mitigation “{name}” could not be saved',
        description: 'Highway Mitigation page',
    },
    mitigationDeleted: {
        id: 'mitigationDeleted',
        defaultMessage: 'Mitigation Deleted',
        description: 'Highway Mitigation page',
    },
    deleteMitigation: {
        id: 'deleteMitigation',
        defaultMessage: 'Delete Mitigation',
        description: 'Highway Mitigation page',
    },
    yesConfirmDeleteMitigation: {
        id: 'yesConfirmDeleteMitigation',
        defaultMessage: 'Yes, Delete Mitigation',
        description: 'Highway Mitigation page',
    },
    deleteMitigationConfirmation: {
        id: 'deleteMitigationConfirmation',
        defaultMessage: 'Are you sure you want to delete this mitigation?',
        description: 'Highway Mitigation page',
    },
    expireNow: {
        id: 'expireNow',
        defaultMessage: 'Expire Now',
        description: 'Product List',
    },
    productExpiredNow: {
        id: 'productExpiredNow',
        defaultMessage: 'Product “{name}” expired',
        description: 'Notification popup',
    },
    expireNowError: {
        id: 'expireNowError',
        defaultMessage: 'Error expiring product',
        description: 'Notification popup',
    },
    expireProductConfirmation: {
        id: 'expireProductConfirmation',
        defaultMessage: 'Are you sure you want to expire “{name}”?',
        description: 'Delete confirmation modal',
    },
    yesConfirmExpireNow: {
        id: 'yesConfirmExpireNow',
        defaultMessage: 'Yes, Expire Now',
        description: 'Product expiration',
    },
    profileSettingsUpdated: {
        id: 'profileSettingsUpdated',
        defaultMessage: 'Preferences saved',
        description: 'Notification message when profile settings are updated',
    },
    noRoadHit: {
        id: 'noRoadHit',
        defaultMessage: 'No Road Hit',
        description: 'Highway Forecasts Avalanche Problems form',
    },
    whiteLine: {
        id: 'whiteLine',
        defaultMessage: 'White Line',
        description: 'Highway Forecasts Avalanche Problems form',
    },
    centerLine: {
        id: 'centerLine',
        defaultMessage: 'Center Line',
        description: 'Highway Forecasts Avalanche Problems form',
    },
    outsideLane: {
        id: 'outsideLane',
        defaultMessage: 'Outside Lane(s)',
        description: 'Highway Forecasts Avalanche Problems form',
    },
    allLanes: {
        id: 'allLanes',
        defaultMessage: 'All Lanes Covered',
        description: 'Highway Forecasts Avalanche Problems form',
    },
    dateTakenExtracted: {
        id: 'dateTakenExtracted',
        defaultMessage: 'The date taken has been set to {date} per the image EXIF data',
        description: 'Avalanche Forecast Photos',
    },
    operationalRecommendations: {
        id: 'operationalRecommendations',
        defaultMessage: 'Operational Recommendations',
        description: 'Hwy Disc header',
    },
    mitigationRecommendations: {
        id: 'mitigationRecommendations',
        defaultMessage: 'Mitigation Recommendations',
        description: 'Hwy Disc header',
    },
    mitigationOutlook: {
        id: 'mitigationOutlook',
        defaultMessage: 'Mitigation Outlook',
        description: 'Mitigation Outlook Selects',
    },
    Monday: {
        id: 'Monday',
        defaultMessage: 'Monday',
        description: 'Day of the week',
    },
    Tuesday: {
        id: 'Tuesday',
        defaultMessage: 'Tuesday',
        description: 'Day of the week',
    },
    Wednesday: {
        id: 'Wednesday',
        defaultMessage: 'Wednesday',
        description: 'Day of the week',
    },
    Thursday: {
        id: 'Thursday',
        defaultMessage: 'Thursday',
        description: 'Day of the week',
    },
    Friday: {
        id: 'Friday',
        defaultMessage: 'Friday',
        description: 'Day of the week',
    },
    Saturday: {
        id: 'Saturday',
        defaultMessage: 'Saturday',
        description: 'Day of the week',
    },
    Sunday: {
        id: 'Sunday',
        defaultMessage: 'Sunday',
        description: 'Day of the week',
    },
    previewEmail: {
        id: 'previewEmail',
        defaultMessage: 'Preview & Send Email',
        description: 'Email Preview',
    },
    sendEmail: {
        id: 'sendEmail',
        defaultMessage: 'Send Email',
        description: 'Email Preview',
    },
    emailSent: {
        id: 'emailSent',
        defaultMessage: 'Email Sent',
        description: 'Email Modal',
    },
    generatingScreenshots: {
        id: 'generatingScreenshots',
        defaultMessage: 'Generating {total, plural, =1 {screenshot} other {screenshots}} ({current}/{total})… ',
        description: 'Email Preview',
    },
    done: {
        id: 'done',
        defaultMessage: 'Done',
        description: 'Email Preview',
    },
    noGroupIds: {
        id: 'noGroupIds',
        defaultMessage:
            'No path groupings exist for this product. Check that there are some live, unexpired highway forecasts for this discussion.',
        description: 'Email Preview',
    },
    emailError: {
        id: 'emailError',
        defaultMessage: 'Error sending email',
        description: 'Email Modal',
    },
    fieldsNotTranslated: {
        id: 'fieldsNotTranslated',
        defaultMessage: 'Auto Translation',
        description: 'Avalanche Forecast Setup',
    },
    doYouWantToTranslate: {
        id: 'doYouWantToTranslate',
        defaultMessage:
            'It looks like some fields are not translated. Do you want to auto translate the forecast? If you think this message is in error, please refresh the page and try again.',
        description: 'Avalanche Forecast Setup',
    },
    untranslatedField: {
        id: 'untranslatedField',
        defaultMessage: '{section} day {day} ({lang})',
        description: 'Avalanche Forecast Setup',
    },
    translate: {
        id: 'translate',
        defaultMessage: 'Translate',
        description: 'Avalanche Forecast Setup',
    },
    translateAndOpenPreview: {
        id: 'translateAndOpenPreview',
        defaultMessage: 'Translate & Open Preview',
        description: 'Avalanche Forecast Setup',
    },
    translateAndPublish: {
        id: 'translateAndPublish',
        defaultMessage: 'Translate & Publish',
        description: 'Avalanche Forecast Setup',
    },
    noTakeMeDirectlyToPreview: {
        id: 'noTakeMeDirectlyToPreview',
        defaultMessage: 'No, Take Me Directly To Preview',
        description: 'Avalanche Forecast Setup',
    },
    noPublish: {
        id: 'noPublish',
        defaultMessage: 'No, Publish Now',
        description: 'Avalanche Forecast Setup',
    },
    multiDayForecast: {
        id: 'multiDayForecast',
        defaultMessage: 'Multi-Day Forecast',
        description: 'Avalanche Forecast Setup Form',
    },
    yes: {
        id: 'yes',
        defaultMessage: 'Yes',
        description: 'Avalanche Forecast Setup Form',
    },
    no: {
        id: 'no',
        defaultMessage: 'No',
        description: 'Avalanche Forecast Setup Form',
    },
    multiDayForecastInfo1: {
        id: 'multiDayForecastInfo1',
        defaultMessage:
            'Off/No indicates that the public display will remain as the danger rating colour from Day 1 indefinitely, until a new forecast is published',
        description: 'Avalanche Forecast Setup Form',
    },
    multiDayForecastInfo2: {
        id: 'multiDayForecastInfo2',
        defaultMessage:
            'On/Yes indicates that the public display will change danger rating colours from Days 1 to 2, 3, and 4, occurring at 4 PM (based on entered time zone) each day. Once Day 4 is reached, the Day 4 danger rating colour will remain indefinitely, until a new forecast is published.',
        description: 'Avalanche Forecast Setup Form',
    },
    multiDayForecastInfo3: {
        id: 'multiDayForecastInfo3',
        defaultMessage:
            'On/Yes indicates that the public display will change danger rating colours from Days 1 to 2, 3, and 4, occurring at 4 PM each day. Once Day 4 is reached, the Day 4 danger rating colour will remain indefinitely, until a new forecast is published.',
        description: 'Avalanche Forecast Setup Form',
    },
    timezone: {
        id: 'timezone',
        defaultMessage: 'Timezone',
        description: 'Avalanche Forecast Setup Form',
    },
    'timezone.pacific': {
        id: 'timezone.pacific',
        defaultMessage: 'Pacific Time',
        description: 'Avalanche Forecast Setup Form',
    },
    'timezone.mountain.ca': {
        id: 'timezone.mountain.ca',
        defaultMessage: 'Mountain Time (Canada)',
        description: 'Avalanche Forecast Setup Form',
    },
    'timezone.mountain.us': {
        id: 'timezone.mountain.us',
        defaultMessage: 'Mountain Time (US)',
        description: 'Avalanche Forecast Setup Form',
    },
    'timezone.eastern': {
        id: 'timezone.eastern',
        defaultMessage: 'Eastern Time',
        description: 'Avalanche Forecast Setup Form',
    },
    'timezone.newfoundland': {
        id: 'timezone.newfoundland',
        defaultMessage: 'Newfoundland Time',
        description: 'Avalanche Forecast Setup Form',
    },
    autoTranslateForecast: {
        id: 'autoTranslateForecast',
        defaultMessage: 'Translate',
        description: 'Avalanche Forecast Setup Form',
    },
})
