import { Popover } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'

import { useSettings } from 'hooks/Settings'
import messages from 'services/intl/messageDefinitions'

export const MultiDayTooltip = () => {
    const { data: settings } = useSettings()
    const { showTimezoneSelector } = settings?.data?.featureFlags || {}

    const content = (
        <div style={styles.multiDayInfoContent}>
            <p>
                <FormattedMessage {...messages.multiDayForecastInfo1} />
            </p>
            {showTimezoneSelector ? (
                <p>
                    <FormattedMessage {...messages.multiDayForecastInfo2} />
                </p>
            ) : (
                <p>
                    <FormattedMessage {...messages.multiDayForecastInfo3} />
                </p>
            )}
        </div>
    )

    return (
        <Popover content={content}>
            <QuestionCircleOutlined style={styles.infoIcon} />
        </Popover>
    )
}

const styles = {
    infoIcon: {
        marginLeft: 'var(--s-1)',
    },
    multiDayInfoContent: {
        maxWidth: '300px',
    },
}
