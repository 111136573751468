import axios from 'axios'
import { Auth0Client } from '@auth0/auth0-spa-js'

const authClient = new Auth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
})

export const DeepLClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_API_KEY,
    },
})

DeepLClient.interceptors.request.use(async (request) => {
    try {
        let token
        token = await authClient.getTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
        })

        request.headers['Authorization'] = `Bearer ${token}`

        return request
    } catch (error) {
        console.error('Translation error:', error)
        throw error
    }
})
