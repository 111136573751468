import { getTimezoneOffset } from 'date-fns-tz'

export const CONVERT_TO_HOURS = 60000

export const convertBrowserTimeToServerTime = (date: Date, timezone: string): Date => {
    const targetOffset = getTimezoneOffset(timezone, date)
    const localOffset = date.getTimezoneOffset() * CONVERT_TO_HOURS
    // NOTE: have to be careful here about positive and negative. This seems to be correct
    const offsetDiff = localOffset + targetOffset
    return new Date(date.getTime() - offsetDiff)
}

export const convertServerTimeToBrowserTime = (date: Date, timezone: string): Date => {
    const targetOffset = getTimezoneOffset(timezone, date)
    const localOffset = date.getTimezoneOffset() * CONVERT_TO_HOURS
    // NOTE: have to be careful here about positive and negative. This seems to be correct
    const offsetDiff = localOffset + targetOffset
    return new Date(date.getTime() + offsetDiff)
}
