import { useCallback, useMemo, useState } from 'react'

import { Button, Modal } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import { CreateForm } from 'components/AvalancheForecast/Setup/CreateForm'
import { DetailsContent } from 'components/AvalancheForecast/Setup/DetailsContent'
import { translateForecast } from 'components/AvalancheForecast/Setup/utils'
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner'
import { useOpenPreview } from 'components/Product/PreviewButton'
import { useAvalancheForecasts } from 'hooks/avalancheForecasts'
import { useCharacterLimits, useSettings } from 'hooks/Settings'
import {
    cloneAvalancheForecast,
    deleteAvalancheForecast,
    publishAvalancheForecast,
    putAvalancheForecast,
} from 'services/AvalancheForecast'
import messages from 'services/intl/messageDefinitions'
import { AvalancheForecastStore } from 'stores/AvalancheForecastStore'
import { useLocale } from 'stores/UserStore'
import { checkAllCharLimits } from 'utils/AvalancheForecast'
import { API_SUCCESS_CODES } from 'utils/Constants'
import { sendErrorNotification, useSendNotification } from 'utils/Notifications'
import { getDrawerDetailsWidth } from 'utils/screen'

export const Details = ({ item, closeAndReload, closeAndOpenCloned, showContent, viewWidth = 36 }) => {
    // External imports
    const intl = useIntl()

    // Store data
    const locale = useLocale()
    const editing = AvalancheForecastStore.useEditingProductSetup()
    const setEditing = AvalancheForecastStore.useSetEditingProductSetup()
    const { mutate } = useAvalancheForecasts()
    const characterLimits = useCharacterLimits()
    const openPreview = useOpenPreview()
    const { data } = useSettings()
    const featureFlags = data?.data?.featureFlags || {}
    const { autoTranslate } = featureFlags

    // Local State
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [cloneError, setCloneError] = useState(false)
    const [translationRequired, setTranslationRequired] = useState(false)
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
    const [footerToShow, setFooterToShow] = useState('preview')
    const detailsColWidth = getDrawerDetailsWidth(viewWidth)

    // Notifications
    const sendNotification = useSendNotification()

    const updateAvalancheForecast = useCallback(async (data, locale) => {
        const response = await putAvalancheForecast(data, locale)
        if (API_SUCCESS_CODES.includes(response.status)) {
            sendNotification(intl.formatMessage({ ...messages.avalancheForecastSaved }, { name: data.name }))
            mutate()
        } else {
            sendErrorNotification(intl.formatMessage({ ...messages.avalancheForecastSaveError }, { name: data.name }))
        }
    }, [])

    const cloneForecast = useCallback(async (item, locale) => {
        const response = await cloneAvalancheForecast(item.id, locale)
        if (API_SUCCESS_CODES.includes(response.status)) {
            closeAndOpenCloned(response.data.id)
        } else {
            if (response.data && response.data.message === 'Polygons are assigned to draft product') {
                setCloneError(true)
            } else {
                sendErrorNotification(intl.formatMessage({ ...messages.productCloneError }))
            }
        }
    }, [])

    const handlePreviewClick = useCallback(async () => {
        if (!autoTranslate || item.isAutoTranslated) {
            openPreview(item.id)
            return
        }

        setTranslationRequired(true)
        setFooterToShow('preview')
    }, [item, openPreview, autoTranslate])

    const handleAutoTranslate = useCallback(
        async (publishOrPreviewOnFinish = null) => {
            setShowLoadingSpinner(true)

            const newForecast = await translateForecast(item, locale)
            if (newForecast) {
                await updateAvalancheForecast(newForecast, locale)
            }

            setShowLoadingSpinner(false)
            setTranslationRequired(false)

            if (!newForecast) {
                sendErrorNotification(
                    intl.formatMessage({ ...messages.avalancheForecastSaveError }, { name: item.name })
                )
                return
            }

            if (!publishOrPreviewOnFinish) return

            if (publishOrPreviewOnFinish === 'preview') {
                await openPreview(item.id)
            }

            if (publishOrPreviewOnFinish === 'publish') {
                await publishAvalancheForecast(item.id, locale)
                mutate()
            }
        },
        [intl, item, locale, mutate, openPreview, translationRequired, updateAvalancheForecast]
    )

    const publish = useCallback(
        async item => {
            if (autoTranslate && !item.isAutoTranslated) {
                setFooterToShow('publish')
                setTranslationRequired(true)
                return
            }

            await publishAvalancheForecast(item.id, locale)
            mutate()
        },
        [autoTranslate, locale, mutate]
    )

    const deleteForecast = useCallback(async (id, locale) => {
        await deleteAvalancheForecast(id, locale)
        sendNotification(intl.formatMessage({ ...messages.avalancheForecastDeleted }, { name: item.name }))
        setDeleteModalVisible(false)
        mutate()
        closeAndReload()
    }, [])

    // Updates the various fields in the avalanche forecast
    // details should be in the format: [{key: <field to be changed> value: <updated values>}, {...}]
    const update = details => {
        const data = { ...item }
        for (let d in details) {
            const detail = details[d]
            data[detail.key] = detail.value
        }

        updateAvalancheForecast(data, locale)
    }

    const toggleEdit = () => {
        setEditing(!editing)
    }

    const toggleTranslated = () => {
        update([{ key: 'isTranslated', value: !item.isTranslated }])
    }

    const disablePublish = useMemo(() => {
        return checkAllCharLimits(item, characterLimits).length > 0
    }, [item, characterLimits])

    if (showLoadingSpinner) {
        return <LoadingSpinner />
    }

    return (
        <>
            {editing ? (
                <CreateForm
                    toggleEdit={toggleEdit}
                    editing={editing}
                    update={update}
                    initialValues={item}
                    autoUpdate={true}
                    closeAndReload={closeAndReload}
                    setDeleteModalVisible={setDeleteModalVisible}
                    destroyOnClose={true}
                />
            ) : (
                <DetailsContent
                    item={item}
                    detailsColWidth={detailsColWidth}
                    disablePublish={disablePublish}
                    toggleEdit={toggleEdit}
                    cloneForecast={cloneForecast}
                    publish={publish}
                    setDeleteModalVisible={setDeleteModalVisible}
                    toggleTranslated={toggleTranslated}
                    showContent={showContent}
                    mutate={mutate}
                    locale={locale}
                    handlePreviewClick={handlePreviewClick}
                    autoTranslateForecast={handleAutoTranslate}
                />
            )}
            <Modal
                title={<FormattedMessage {...messages.warning} />}
                visible={deleteModalVisible}
                onOk={() => deleteForecast(item.id, locale)}
                onCancel={() => setDeleteModalVisible(false)}
                okText={<FormattedMessage {...messages.yesConfirmDeleteAvalancheForecast} />}
                cancelText={<FormattedMessage {...messages.cancel} />}
                data-test={'deleteForecastModal'}
            >
                <p>
                    <FormattedMessage {...messages.deleteAvalancheForecastConfirmation} values={{ name: item.name }} />
                </p>
            </Modal>
            <Modal
                title={<FormattedMessage {...messages.clonePolygonError} />}
                visible={cloneError}
                onCancel={() => setCloneError(false)}
                okText={<FormattedMessage {...messages.ok} />}
                footer={
                    <Button type="primary" onClick={() => setCloneError(false)}>
                        <FormattedMessage {...messages.ok} />
                    </Button>
                }
            >
                <p>
                    <FormattedMessage {...messages.productCloneErrorPolygons} />
                </p>
            </Modal>
            <Modal
                title={<FormattedMessage {...messages.fieldsNotTranslated} />}
                visible={translationRequired}
                onCancel={() => setTranslationRequired(false)}
                width={600}
                footer={
                    <>
                        {footerToShow === 'preview' && (
                            <PreviewTranslationModalFooter
                                handleAutoTranslate={handleAutoTranslate}
                                openPreview={openPreview}
                                item={item}
                            />
                        )}
                        {footerToShow === 'publish' && (
                            <PublishTranslationModalFooter
                                handleAutoTranslate={handleAutoTranslate}
                                publishAvalancheForecast={publishAvalancheForecast}
                                mutate={mutate}
                                publish={publish}
                                item={item}
                                locale={locale}
                            />
                        )}
                    </>
                }
            >
                <p>
                    <FormattedMessage {...messages.doYouWantToTranslate} />
                </p>
            </Modal>
        </>
    )
}

const PreviewTranslationModalFooter = ({ handleAutoTranslate, openPreview, item }) => {
    return (
        <>
            <Button type="primary" onClick={() => handleAutoTranslate(null)}>
                <FormattedMessage {...messages.translate} />
            </Button>
            <Button type="primary" onClick={() => handleAutoTranslate('preview')}>
                <FormattedMessage {...messages.translateAndOpenPreview} />
            </Button>
            <Button type="default" onClick={() => openPreview(item.id)}>
                <FormattedMessage {...messages.noTakeMeDirectlyToPreview} />
            </Button>
        </>
    )
}

const PublishTranslationModalFooter = ({ handleAutoTranslate, publishAvalancheForecast, mutate, item, locale }) => {
    return (
        <>
            <Button type="primary" onClick={() => handleAutoTranslate(null)}>
                <FormattedMessage {...messages.translate} />
            </Button>
            <Button type="primary" onClick={() => handleAutoTranslate('publish')}>
                <FormattedMessage {...messages.translateAndPublish} />
            </Button>
            <Button
                type="default"
                onClick={() => {
                    publishAvalancheForecast(item.id, locale)
                    mutate()
                }}
            >
                <FormattedMessage {...messages.noPublish} />
            </Button>
        </>
    )
}
