import useSWR from 'swr'

import { useLocale } from 'stores/UserStore'
import { fetchAvalancheForecasts } from 'services/AvalancheForecast'
import { AvalancheForecastStore } from 'stores/AvalancheForecastStore'
import { filterAndSort, unassignedCount } from 'utils/Product'
import { usePolygons } from 'hooks/polygons'

export const useAvalancheForecasts = () => {
    const language = useLocale()
    const key = ['products/avalancheforecast', language]
    const { data, isLoading, mutate } = useSWR(key, () => fetchAvalancheForecasts(language))

    return {
        avalancheForecasts: data?.data,
        isLoading,
        mutate,
    }
}

export const useFilteredAvalancheForecasts = () => {
    const { avalancheForecasts, isLoading } = useAvalancheForecasts()
    const filters = AvalancheForecastStore.useFilters()

    return isLoading ? [] : filterAndSort(avalancheForecasts, 'status', filters)
}

export const useActiveAvalancheForecast = () => {
    const activeProductId = AvalancheForecastStore.useActiveProductId()
    const { avalancheForecasts } = useAvalancheForecasts()
    const draftProduct = AvalancheForecastStore.useDraftProduct()

    if (activeProductId === 'draft') {
        return draftProduct
    }

    return avalancheForecasts?.find(product => product.id === activeProductId)
}

export const useUnassignedPolygons = () => {
    const polygons = usePolygons()
    const draftProduct = AvalancheForecastStore.useDraftProduct()
    const filteredProducts = useFilteredAvalancheForecasts()

    if (!polygons || !filteredProducts || !draftProduct) {
        return -1
    }

    const count = unassignedCount(polygons, filteredProducts, draftProduct)

    return count
}
