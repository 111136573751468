import { Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useAuth0 } from '@auth0/auth0-react'

import { useLocale } from 'stores/UserStore'
import messages from 'services/intl/messageDefinitions'

const OPERATION_NAME = process.env.REACT_APP_OPERATION_NAME
const BASE_URL = process.env.REACT_APP_PREVIEW_BASE_URL
const API_URL = process.env.REACT_APP_API_URL
const API_KEY = process.env.REACT_APP_API_KEY

export const PreviewButton = ({ id, handlePreviewClick }) => {
    const openPreview = useOpenPreview()

    return (
        <Button
            onClick={async () => {
                if (handlePreviewClick) {
                    handlePreviewClick()
                    return
                }

                openPreview(id)
            }}
        >
            <FormattedMessage {...messages.preview} />
        </Button>
    )
}

export const useOpenPreview = () => {
    const locale = useLocale()
    const { getAccessTokenSilently } = useAuth0()

    return async (id) => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
        })

        const url =
            OPERATION_NAME === 'CAIC'
                ? `${BASE_URL}/?product=${id}&apiUrl=${API_URL}&apiKey=${API_KEY}&token=${token}`
                : `${BASE_URL}/${locale}/preview?product=${id}&apiUrl=${API_URL}&apiKey=${API_KEY}&token=${token}`

        window.open(url, '_blank')
    }
}
